/**
 * Сервис для работы с каналами
 */
import axios from 'axios';
import { getCache, setCache, CACHE_TTL } from './cacheService';

/**
 * Получить недавно добавленные каналы
 * @param {number} limit - количество каналов
 */
export const channelService = {
  /**
   * Получить недавно добавленные каналы
   * @param {number} limit - количество каналов
   */
  async getRecent(limit = 8) {
    const cacheKey = `recent_${limit}`;
    const cachedData = getCache(cacheKey);
    
    if (cachedData) {
      console.log('[DEBUG] channelService.getRecent - Данные из кэша:', cachedData);
      return { data: cachedData };
    }
    
    try {
      console.log('[DEBUG] channelService.getRecent - Запрос к API');
      const response = await axios.get('/api/channels/homepage', { 
        params: { 
          sort: 'recent', 
          limit 
        } 
      });
      
      console.log('[DEBUG] channelService.getRecent - Ответ API:', response.data);
      
      // Извлекаем массив каналов из ответа
      const channels = response.data.channels || [];
      
      // Сохраняем в кэш, используя предопределенный TTL для недавних каналов
      setCache(cacheKey, channels, CACHE_TTL.CHANNEL_RECENT);
      
      return { data: channels };
    } catch (error) {
      console.error('Ошибка при получении недавних каналов:', error);
      throw error;
    }
  },
  
  /**
   * Получить популярные каналы
   * @param {number} limit - количество каналов
   */
  async getPopular(limit = 8) {
    const cacheKey = `popular_${limit}`;
    const cachedData = getCache(cacheKey);
    
    if (cachedData) {
      console.log('[DEBUG] channelService.getPopular - Данные из кэша:', cachedData);
      return { data: cachedData };
    }
    
    try {
      console.log('[DEBUG] channelService.getPopular - Запрос к API');
      const response = await axios.get('/api/channels/homepage', { 
        params: { 
          sort: 'popular', 
          limit 
        } 
      });
      
      console.log('[DEBUG] channelService.getPopular - Ответ API:', response.data);
      
      // Извлекаем массив каналов из ответа
      const channels = response.data.channels || [];
      
      // Сохраняем в кэш, используя предопределенный TTL для популярных каналов
      setCache(cacheKey, channels, CACHE_TTL.CHANNEL_POPULAR);
      
      return { data: channels };
    } catch (error) {
      console.error('Ошибка при получении популярных каналов:', error);
      throw error;
    }
  },
  
  /**
   * Получить рекомендованные каналы
   * @param {number} limit - количество каналов
   */
  async getRecommended(limit = 8) {
    const cacheKey = `recommended_${limit}`;
    const cachedData = getCache(cacheKey);
    
    if (cachedData) {
      console.log('[DEBUG] channelService.getRecommended - Данные из кэша:', cachedData);
      return { data: cachedData };
    }
    
    try {
      console.log('[DEBUG] channelService.getRecommended - Запрос к API');
      const response = await axios.get('/api/channels/homepage', { 
        params: { 
          sort: 'recommended', 
          limit 
        } 
      });
      
      console.log('[DEBUG] channelService.getRecommended - Ответ API:', response.data);
      
      // Извлекаем массив каналов из ответа
      const channels = response.data.channels || [];
      
      // Сохраняем в кэш, используя предопределенный TTL для рекомендованных каналов
      setCache(cacheKey, channels, CACHE_TTL.CHANNEL_RECOMMENDED);
      
      return { data: channels };
    } catch (error) {
      console.error('Ошибка при получении рекомендованных каналов:', error);
      throw error;
    }
  },
  
  /**
   * Получить информацию о канале по его имени пользователя
   * @param {string} username - Имя пользователя канала
   * @returns {Promise} - Промис с результатом запроса
   */
  async getByUsername(username) {
    if (!username) {
      throw new Error('Не указано имя пользователя канала');
    }
    
    const cacheKey = `channel_${username}`;
    const cachedData = getCache(cacheKey);
    
    if (cachedData) {
      console.log('[DEBUG] channelService.getByUsername - Данные из кэша:', cachedData);
      return { data: cachedData };
    }
    
    try {
      console.log('[DEBUG] channelService.getByUsername - Запрос к API');
      const response = await axios.get(`/api/channels/${username}`);
      
      console.log('[DEBUG] channelService.getByUsername - Ответ API:', response.data);
      
      // Сохраняем в кэш, используя предопределенный TTL для данных канала
      setCache(cacheKey, response.data, CACHE_TTL.CHANNEL);
      
      return { data: response.data };
    } catch (error) {
      console.error(`Ошибка при получении канала ${username}:`, error);
      throw error;
    }
  }
};
