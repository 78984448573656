/**
 * API для работы с SEO-метаданными
 * Прямое взаимодействие с бэкендом без промежуточных слоев
 * С поддержкой кэширования для оптимизации производительности
 */
import api from './api';
import { getCache, setCache, clearAllCache } from './cacheService';

// Константы для времени жизни кэша разных типов SEO-метаданных
const CACHE_TTL = {
  // Статические страницы кэшируются на 24 часа
  STATIC: 24 * 60 * 60 * 1000,   // 24 часа
  // Динамические страницы кэшируются на 1 час
  DYNAMIC: 60 * 60 * 1000,       // 1 час
  // Часто обновляемые страницы кэшируются на 15 минут
  FREQUENT: 15 * 60 * 1000,      // 15 минут
  // Главная страница кэшируется на 30 минут
  HOMEPAGE: 30 * 60 * 1000       // 30 минут
};

/**
 * Получить SEO-метаданные для канала
 * @param {string} slug - Идентификатор канала
 * @returns {Promise<Object>} SEO-метаданные
 */
export const getChannelSeo = async (slug) => {
  try {
    // Формируем ключ для кэша
    const cacheKey = `seo_channel_${slug}`;
    
    // Проверяем наличие данных в кэше
    const cachedData = getCache(cacheKey);
    if (cachedData) {
      console.log('[DEBUG] seoApi - Получены SEO-метаданные из кэша для канала:', slug);
      return cachedData;
    }
    
    console.log('[DEBUG] seoApi - Запрос SEO-метаданных для канала:', slug);
    
    const response = await api.get(`/api/seo/channel/${slug}`);
    
    // Проверяем, что ответ содержит все необходимые данные
    const hasData = response.data && 
                   (response.data.title || response.data.description || response.data.canonicalUrl);
    
    console.log('[DEBUG] seoApi - Получен ответ от сервера:', {
      status: response.status,
      hasData,
      title: response.data?.title || 'Отсутствует',
      description: response.data?.description ? 'Присутствует' : 'Отсутствует'
    });
    
    if (hasData) {
      const seoData = response.data;
      
      // Сохраняем данные в кэш на 1 час (каналы могут обновляться)
      setCache(cacheKey, seoData, CACHE_TTL.DYNAMIC);
      
      return seoData;
    }
    
    console.error('[ERROR] seoApi - Не удалось получить SEO-метаданные для канала:', slug);
    throw new Error(`Не удалось получить SEO-метаданные для канала: ${slug}`);
  } catch (error) {
    console.error('[ERROR] seoApi - Ошибка при получении SEO-метаданных канала:', error);
    throw error;
  }
};

/**
 * Получить SEO-метаданные для категории
 * @param {string} slug - Идентификатор категории
 * @returns {Promise<Object>} SEO-метаданные
 */
export const getCategorySeo = async (slug) => {
  try {
    console.log(`[DEBUG] seoApi.getCategorySeo - Начинаем получение SEO-метаданных для категории: ${slug || 'не указан'}`);
    
    // Проверяем, что slug не пуст
    if (!slug) {
      console.warn('[WARN] seoApi.getCategorySeo - Не указан slug категории, возвращаем базовые метаданные');
      return getDefaultCategorySeo();
    }
    
    // Формируем ключ для кэша
    const cacheKey = `seo_category_${slug}`;
    
    // Проверяем наличие данных в кэше
    const cachedData = getCache(cacheKey);
    if (cachedData) {
      console.log(`[DEBUG] seoApi.getCategorySeo - Используем кэшированные SEO-метаданные для категории: ${slug}`);
      return cachedData;
    }
    
    console.log(`[DEBUG] seoApi.getCategorySeo - Кэш пуст, выполняем запрос к API для категории: ${slug}`);
    
    // Получаем текущий URL фронтенда для формирования канонического URL
    const frontendUrl = window.location.origin;
    console.log(`[DEBUG] seoApi.getCategorySeo - Текущий URL фронтенда: ${frontendUrl}`);
    
    // Выполняем запрос к API
    const response = await api.get(`/api/seo/category/${slug}`, {
      headers: {
        'X-Frontend-Url': frontendUrl
      },
      timeout: 5000 // Увеличиваем таймаут до 5 секунд для SEO-запросов
    });
    
    console.log(`[DEBUG] seoApi.getCategorySeo - Полный ответ от API для категории ${slug}:`, response);
    
    // Проверяем, что ответ содержит все необходимые данные
    if (!response || !response.data) {
      console.error(`[ERROR] seoApi.getCategorySeo - Пустой ответ от API для категории: ${slug}`);
      return getDefaultCategorySeo(slug);
    }
    
    const seoData = response.data;
    const hasRequiredFields = seoData && 
                          (seoData.title || seoData.description || seoData.canonicalUrl);
    
    if (!hasRequiredFields) {
      console.error(`[ERROR] seoApi.getCategorySeo - Некорректные SEO-метаданные в ответе от API для категории ${slug}:`, seoData);
      return getDefaultCategorySeo(slug);
    }
    
    console.log(`[DEBUG] seoApi.getCategorySeo - Успешно получены SEO-метаданные для категории: ${slug}`);
    
    // Сохраняем данные в кэш на 1 час (категории обновляются редко)
    setCache(cacheKey, seoData, 3600);
    
    return seoData;
  } catch (error) {
    console.error(`[ERROR] seoApi.getCategorySeo - Ошибка при получении SEO-метаданных для категории ${slug}:`, error);
    return getDefaultCategorySeo(slug);
  }
};

/**
 * Получить базовые SEO-метаданные для категории при ошибке
 * @param {string} slug - Идентификатор категории
 * @returns {Object} Базовые SEO-метаданные
 */
const getDefaultCategorySeo = (slug = '') => {
  console.log(`[DEBUG] seoApi.getDefaultCategorySeo - Формирование дефолтных метаданных для категории: ${slug}`);
  
  const frontendUrl = window.location.origin;
  const categoryName = slug.charAt(0).toUpperCase() + slug.slice(1).replace(/-/g, ' ');
  
  return {
    title: `Категория ${categoryName} | TeleHunt`,
    description: `Лучшие Telegram каналы в категории ${categoryName}. Подборка популярных каналов и обсуждений.`,
    keywords: `telegram, каналы, категория, ${categoryName}, подборка`,
    ogTitle: `Категория ${categoryName} | TeleHunt`,
    ogDescription: `Лучшие Telegram каналы в категории ${categoryName}`,
    ogImage: '/images/seo/og-image.jpg',
    canonicalUrl: `${frontendUrl}/category/${slug}`,
    schema: {
      '@context': 'https://schema.org',
      '@type': 'CollectionPage',
      'name': `Категория ${categoryName} | TeleHunt`,
      'description': `Лучшие Telegram каналы в категории ${categoryName}`,
      'url': `${frontendUrl}/category/${slug}`
    }
  };
};

/**
 * Очистить весь кеш SEO-метаданных
 * Полезно для отладки и тестирования
 */
export const clearSeoCache = () => {
  console.log('[DEBUG] seoApi - Очистка всего кеша SEO-метаданных');
  clearAllCache();
};

/**
 * Получить SEO-метаданные для главной страницы
 * @returns {Promise<Object>} - Объект с SEO-метаданными
 */
export const getHomeSeo = async () => {
  try {
    console.log('[DEBUG] seoApi.getHomeSeo - Начинаем получение SEO-метаданных для главной страницы');
    
    // Проверяем кэш
    const cacheKey = 'seo_homepage';
    const cachedData = getCache(cacheKey);
    if (cachedData) {
      console.log('[DEBUG] seoApi.getHomeSeo - Используем кэшированные SEO-метаданные');
      return cachedData;
    }
    
    console.log('[DEBUG] seoApi.getHomeSeo - Кэш пуст, выполняем запрос к API');
    
    // Получаем текущий URL фронтенда для формирования канонического URL
    const frontendUrl = window.location.origin;
    console.log(`[DEBUG] seoApi.getHomeSeo - Текущий URL фронтенда: ${frontendUrl}`);
    
    // Выполняем запрос к API
    const response = await api.get('/api/seo/homepage');
    console.log('[DEBUG] seoApi.getHomeSeo - Полный ответ от API:', response);
    console.log('[DEBUG] seoApi.getHomeSeo - Полные данные от API:', response.data);
    
    // Проверяем наличие данных в ответе
    if (!response || !response.data) {
      console.error('[ERROR] seoApi.getHomeSeo - Пустой ответ от API');
      return getDefaultSeoMetadata(frontendUrl);
    }
    
    // Формируем объект с SEO-метаданными
    const seoData = response.data;
    console.log('[DEBUG] seoApi.getHomeSeo - Получен ответ от API:', {
      status: response.status,
      hasData: !!seoData,
      title: seoData.title || 'отсутствует',
      description: seoData.description || 'отсутствует',
      fullData: JSON.stringify(seoData)
    });
    
    // Проверяем корректность полученных метаданных
    if (!seoData.title || !seoData.description || Object.keys(seoData).length < 3) {
      console.error('[ERROR] seoApi.getHomeSeo - Некорректные SEO-метаданные в ответе от API:', seoData);
      return getDefaultSeoMetadata(frontendUrl);
    }
    
    // Сохраняем в кэш
    setCache(cacheKey, seoData, 3600); // Кэшируем на 1 час
    
    return seoData;
  } catch (error) {
    console.error('[ERROR] seoApi.getHomeSeo - Ошибка при получении SEO-метаданных:', error);
    return getDefaultSeoMetadata(window.location.origin);
  }
};

/**
 * Получить дефолтные SEO-метаданные для главной страницы
 * @param {string} baseUrl - Базовый URL сайта
 * @returns {Object} - Объект с дефолтными SEO-метаданными
 */
const getDefaultSeoMetadata = (baseUrl) => {
  console.log('[DEBUG] seoApi.getDefaultSeoMetadata - Формирование дефолтных метаданных');
  
  return {
    title: 'TeleHunt - каталог Telegram каналов',
    description: 'Найдите лучшие Telegram каналы в нашем каталоге. Мы собрали самые интересные и полезные каналы в одном месте.',
    keywords: 'telegram, каналы, каталог, подборка',
    ogTitle: 'TeleHunt - каталог Telegram каналов',
    ogDescription: 'Найдите лучшие Telegram каналы в нашем каталоге',
    ogImage: '/images/seo/og-image.jpg',
    canonicalUrl: baseUrl,
    schema: {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      'name': 'TeleHunt - каталог Telegram каналов',
      'description': 'Найдите лучшие Telegram каналы в нашем каталоге',
      'url': baseUrl,
      'publisher': {
        '@type': 'Organization',
        'name': 'TeleHunt',
        'logo': {
          '@type': 'ImageObject',
          'url': `${baseUrl}/images/logo.png`
        }
      }
    }
  };
};

/**
 * Получить SEO-метаданные для статической страницы
 * @param {string} pageType - Тип страницы (terms, privacy, faq, contacts и т.д.)
 * @returns {Promise<Object>} SEO-метаданные
 */
export const getPageSeo = async (pageType) => {
  try {
    // Формируем ключ для кэша
    const cacheKey = `seo_page_${pageType}`;
    
    // Проверяем наличие данных в кэше
    const cachedData = getCache(cacheKey);
    if (cachedData) {
      console.log(`[DEBUG] seoApi - Получены SEO-метаданные из кэша для страницы: ${pageType}`);
      return cachedData;
    }
    
    console.log(`[DEBUG] seoApi - Запрос SEO-метаданных для страницы: ${pageType}`);
    
    // Получаем текущий URL фронтенда
    const frontendUrl = window.location.origin;
    console.log(`[DEBUG] seoApi - URL фронтенда для страницы ${pageType}: ${frontendUrl}`);
    
    const response = await api.get(`/api/seo/page/${pageType}`, {
      headers: {
        'X-Frontend-Url': frontendUrl
      }
    });
    
    // Проверяем, что ответ содержит все необходимые данные
    const responseData = response.data && response.data.data ? response.data.data : response.data;
    const hasData = responseData && 
                    (responseData.title || responseData.description || responseData.canonicalUrl);
    
    if (hasData) {
      const seoData = responseData;
      
      // Сохраняем данные в кэш на 24 часа (статические страницы обновляются очень редко)
      setCache(cacheKey, seoData, CACHE_TTL.STATIC);
      
      return seoData;
    }
    
    console.error(`[ERROR] seoApi - Не удалось получить SEO-метаданные для страницы: ${pageType}`);
    throw new Error(`Не удалось получить SEO-метаданные для страницы: ${pageType}`);
  } catch (error) {
    console.error(`[ERROR] seoApi - Ошибка при получении SEO-метаданных страницы ${pageType}:`, error);
    throw error;
  }
};

/**
 * Получить SEO-метаданные для блога
 * @param {string} slug - Идентификатор статьи (опционально)
 * @returns {Promise<Object>} SEO-метаданные
 */
export const getBlogSeo = async (slug = null) => {
  try {
    // Формируем ключ для кэша
    const cacheKey = slug ? `seo_blog_article_${slug}` : 'seo_blog';
    
    // Проверяем наличие данных в кэше
    const cachedData = getCache(cacheKey);
    if (cachedData) {
      console.log(`[DEBUG] seoApi - Получены SEO-метаданные из кэша для ${slug ? 'статьи блога: ' + slug : 'блога'}`);
      return cachedData;
    }
    
    console.log(`[DEBUG] seoApi - Запрос SEO-метаданных для ${slug ? 'статьи блога: ' + slug : 'блога'}`);
    
    // Формируем URL запроса в зависимости от наличия slug
    const url = slug ? `/api/seo/blog/${slug}` : '/api/seo/blog';
    
    // Получаем текущий URL фронтенда
    const frontendUrl = window.location.origin;
    console.log(`[DEBUG] seoApi - URL фронтенда для ${slug ? 'статьи блога: ' + slug : 'блога'}: ${frontendUrl}`);
    
    const response = await api.get(url, {
      headers: {
        'X-Frontend-Url': frontendUrl
      }
    });
    
    // Проверяем, что ответ содержит все необходимые данные
    const responseData = response.data && response.data.data ? response.data.data : response.data;
    const hasData = responseData && 
                    (responseData.title || responseData.description || responseData.canonicalUrl);
    
    if (hasData) {
      const seoData = responseData;
      
      // Сохраняем данные в кэш (статьи блога обновляются чаще, чем статические страницы)
      setCache(cacheKey, seoData, CACHE_TTL.FREQUENT);
      
      return seoData;
    }
    
    console.error(`[ERROR] seoApi - Не удалось получить SEO-метаданные для ${slug ? 'статьи блога: ' + slug : 'блога'}`);
    throw new Error(`Не удалось получить SEO-метаданные для ${slug ? 'статьи блога: ' + slug : 'блога'}`);
  } catch (error) {
    console.error(`[ERROR] seoApi - Ошибка при получении SEO-метаданных для ${slug ? 'статьи блога: ' + slug : 'блога'}:`, error);
    throw error;
  }
};

/**
 * Получить SEO-метаданные для страницы создания канала
 * @returns {Promise<Object>} SEO-метаданные
 */
export const getCreateChannelSeo = async () => {
  try {
    // Формируем ключ для кэша
    const cacheKey = 'seo_create_channel';
    
    // Проверяем наличие данных в кэше
    const cachedData = getCache(cacheKey);
    if (cachedData) {
      console.log('[DEBUG] seoApi - Получены SEO-метаданные из кэша для страницы создания канала');
      return cachedData;
    }
    
    console.log('[DEBUG] seoApi - Запрос SEO-метаданных для страницы создания канала');
    
    const response = await api.get('/api/seo/create-channel');
    
    // Проверяем, что ответ содержит все необходимые данные
    const hasData = response.data && 
                    (response.data.title || response.data.description || response.data.canonicalUrl);
    
    if (hasData) {
      const seoData = response.data;
      
      // Сохраняем данные в кэш на 24 часа (статические страницы обновляются очень редко)
      setCache(cacheKey, seoData, CACHE_TTL.STATIC);
      
      return seoData;
    }
    
    console.error('[ERROR] seoApi - Не удалось получить SEO-метаданные для страницы создания канала');
    throw new Error('Не удалось получить SEO-метаданные для страницы создания канала');
  } catch (error) {
    console.error('[ERROR] seoApi - Ошибка при получении SEO-метаданных для страницы создания канала:', error);
    throw error;
  }
};

/**
 * Получить SEO-метаданные для страницы каталога
 * @param {string} filter - Фильтр каталога (опционально)
 * @returns {Promise<Object>} SEO-метаданные
 */
export const getCatalogSeo = async (filter = '') => {
  try {
    console.log(`[DEBUG] seoApi.getCatalogSeo - Начинаем получение SEO-метаданных для каталога${filter ? ` с фильтром: ${filter}` : ''}`);
    
    // Формируем ключ для кэша
    const cacheKey = `seo_catalog_${filter || 'default'}`;
    
    // Проверяем наличие данных в кэше
    const cachedData = getCache(cacheKey);
    if (cachedData) {
      console.log(`[DEBUG] seoApi.getCatalogSeo - Используем кэшированные SEO-метаданные для каталога${filter ? ` с фильтром: ${filter}` : ''}`);
      return cachedData;
    }
    
    console.log(`[DEBUG] seoApi.getCatalogSeo - Кэш пуст, выполняем запрос к API для каталога${filter ? ` с фильтром: ${filter}` : ''}`);
    
    // Формируем URL запроса
    const url = '/api/seo/catalog' + (filter ? `?filter=${encodeURIComponent(filter)}` : '');
    
    // Получаем текущий URL фронтенда
    const frontendUrl = window.location.origin;
    console.log(`[DEBUG] seoApi.getCatalogSeo - URL фронтенда для каталога: ${frontendUrl}`);
    
    // Устанавливаем увеличенный таймаут для запроса SEO-метаданных
    const response = await api.get(url, {
      headers: {
        'X-Frontend-Url': frontendUrl
      },
      timeout: 15000 // Увеличиваем таймаут до 15 секунд для SEO-запросов
    });
    
    console.log(`[DEBUG] seoApi.getCatalogSeo - Полный ответ от API для каталога:`, response);
    
    // Проверяем, что ответ содержит все необходимые данные
    const responseData = response.data && response.data.data ? response.data.data : response.data;
    const hasData = responseData && 
                    (responseData.title || responseData.description || responseData.canonicalUrl);
    
    if (hasData) {
      const seoData = responseData;
      
      // Сохраняем данные в кэш на 1 час (каталог может обновляться)
      setCache(cacheKey, seoData, CACHE_TTL.DYNAMIC);
      
      return seoData;
    }
    
    console.error(`[ERROR] seoApi - Не удалось получить SEO-метаданные для каталога${filter ? ` с фильтром: ${filter}` : ''}`);
    
    // Вместо выбрасывания исключения возвращаем базовые SEO-данные
    return getDefaultCatalogSeo(filter);
  } catch (error) {
    console.error(`[ERROR] seoApi - Ошибка при получении SEO-метаданных для каталога${filter ? ` с фильтром: ${filter}` : ''}:`, error);
    
    // Возвращаем базовые SEO-данные вместо выбрасывания исключения
    return getDefaultCatalogSeo(filter);
  }
};

/**
 * Получить базовые SEO-метаданные для каталога при ошибке
 * @param {string} filter - Фильтр каталога (опционально)
 * @returns {Object} Базовые SEO-метаданные
 */
const getDefaultCatalogSeo = (filter = '') => {
  const title = filter 
    ? `Каналы по запросу "${filter}" в каталоге Telegram каналов`
    : 'Каталог Telegram каналов';
  const description = filter 
    ? `Найдите Telegram каналы по запросу "${filter}" в нашем каталоге`
    : 'Найдите лучшие Telegram каналы в нашем каталоге';
  const keywords = filter 
    ? `telegram, каналы, каталог, ${filter}`
    : 'telegram, каналы, каталог, подборка';
  const ogTitle = title;
  const ogDescription = description;
  const ogImage = '/images/seo/og-image.jpg';
  const canonicalUrl = window.location.origin + '/catalog' + (filter ? `?filter=${encodeURIComponent(filter)}` : '');
  const schema = null;
  
  return {
    title,
    description,
    keywords,
    ogTitle,
    ogDescription,
    ogImage,
    canonicalUrl,
    schema
  };
};

/**
 * Получить SEO-метаданные для статической страницы
 * @param {string} pageName - Идентификатор страницы
 * @returns {Promise<Object>} SEO-метаданные
 */
export const getStaticPageSeo = async (pageName) => {
  try {
    console.log(`[DEBUG] seoApi.getStaticPageSeo - Начинаем получение SEO-метаданных для страницы: ${pageName}`);
    
    // Формируем ключ для кэша
    const cacheKey = `seo_page_${pageName}`;
    
    // Проверяем наличие данных в кэше
    const cachedData = getCache(cacheKey);
    if (cachedData) {
      console.log(`[DEBUG] seoApi.getStaticPageSeo - Используем кэшированные SEO-метаданные для страницы: ${pageName}`);
      return cachedData;
    }
    
    console.log(`[DEBUG] seoApi.getStaticPageSeo - Кэш пуст, выполняем запрос к API для страницы: ${pageName}`);
    
    // Получаем текущий URL фронтенда
    const frontendUrl = window.location.origin;
    console.log(`[DEBUG] seoApi.getStaticPageSeo - URL фронтенда для страницы: ${frontendUrl}`);
    
    const response = await api.get(`/api/seo/page/${pageName}`, {
      headers: {
        'X-Frontend-Url': frontendUrl
      },
      timeout: 5000 // Увеличиваем таймаут до 5 секунд для SEO-запросов
    });
    
    console.log(`[DEBUG] seoApi.getStaticPageSeo - Полный ответ от API для страницы: ${pageName}`, response);
    
    // Проверяем, что ответ содержит все необходимые данные
    if (!response || !response.data) {
      console.error(`[ERROR] seoApi.getStaticPageSeo - Пустой ответ от API для страницы: ${pageName}`);
      return getDefaultStaticPageSeo(pageName);
    }
    
    const seoData = response.data;
    const hasRequiredFields = seoData && 
                          (seoData.title || seoData.description || seoData.canonicalUrl);
    
    if (!hasRequiredFields) {
      console.error(`[ERROR] seoApi.getStaticPageSeo - Некорректные SEO-метаданные в ответе от API для страницы: ${pageName}`, seoData);
      return getDefaultStaticPageSeo(pageName);
    }
    
    console.log(`[DEBUG] seoApi.getStaticPageSeo - Успешно получены SEO-метаданные для страницы: ${pageName}`);
    
    // Сохраняем данные в кэш на 1 час (статические страницы обновляются редко)
    setCache(cacheKey, seoData, 3600);
    
    return seoData;
  } catch (error) {
    console.error(`[ERROR] seoApi.getStaticPageSeo - Ошибка при получении SEO-метаданных для страницы: ${pageName}`, error);
    return getDefaultStaticPageSeo(pageName);
  }
};

/**
 * Получить базовые SEO-метаданные для статической страницы при ошибке
 * @param {string} pageName - Идентификатор страницы
 * @returns {Object} Базовые SEO-метаданные
 */
const getDefaultStaticPageSeo = (pageName) => {
  console.log(`[DEBUG] seoApi.getDefaultStaticPageSeo - Формирование дефолтных метаданных для страницы: ${pageName}`);
  
  const frontendUrl = window.location.origin;
  let title, description, path;
  
  // Формируем дефолтные метаданные в зависимости от страницы
  switch (pageName) {
    case 'about':
      title = 'О нас | TeleHunt';
      description = 'Узнайте больше о проекте TeleHunt - каталоге Telegram каналов.';
      path = 'about';
      break;
    case 'contact':
      title = 'Контакты | TeleHunt';
      description = 'Свяжитесь с нами по вопросам, связанным с проектом TeleHunt.';
      path = 'contact';
      break;
    case 'privacy':
      title = 'Политика конфиденциальности | TeleHunt';
      description = 'Узнайте, как мы обрабатываем ваши данные в проекте TeleHunt.';
      path = 'privacy';
      break;
    case 'terms':
      title = 'Условия использования | TeleHunt';
      description = 'Узнайте условия использования проекта TeleHunt.';
      path = 'terms';
      break;
    case 'faq':
      title = 'Часто задаваемые вопросы | TeleHunt';
      description = 'Ответы на часто задаваемые вопросы о проекте TeleHunt.';
      path = 'faq';
      break;
    default:
      title = 'TeleHunt - каталог Telegram каналов';
      description = 'Найдите лучшие Telegram каналы в нашем каталоге.';
      path = pageName;
  }
  
  return {
    title: title,
    description: description,
    keywords: `telegram, каналы, ${pageName}, telehunt`,
    ogTitle: title,
    ogDescription: description,
    ogImage: '/images/seo/og-image.jpg',
    canonicalUrl: `${frontendUrl}/${path}`,
    schema: {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      'name': title,
      'description': description,
      'url': `${frontendUrl}/${path}`
    }
  };
};
