/**
 * Утилиты для работы с медиа-файлами (изображения, аватары и т.д.)
 */

/**
 * Получение URL аватара с учетом размера
 * @param {string} url - URL аватара
 * @param {string} size - Размер аватара (thumbnail, small, medium, large)
 * @returns {string} - URL аватара с учетом размера
 */
export const getAvatarUrl = (url, size = 'medium') => {
  if (!url) {
    return null;
  }

  // Если это внешняя ссылка, возвращаем как есть
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }

  // Проверяем, содержит ли URL уже размер
  const validSizes = ['thumbnail', 'small', 'medium', 'large'];
  for (const validSize of validSizes) {
    if (url.includes(`/${validSize}/`)) {
      // Если URL уже содержит размер, заменяем его на новый
      if (validSize === size) {
        // Если размер уже совпадает, проверяем расширение
        if (url.endsWith('.webp')) {
          return url; // Если размер и расширение совпадают, возвращаем как есть
        }
        // Иначе меняем расширение на webp
        const urlParts = url.split('/');
        const filename = urlParts[urlParts.length - 1];
        const filenameWithoutExt = filename.split('.')[0];
        urlParts[urlParts.length - 1] = `${filenameWithoutExt}.webp`;
        return urlParts.join('/');
      }
      
      // Если размер не совпадает, заменяем его на новый и проверяем расширение
      let newUrl = url.replace(`/${validSize}/`, `/${size}/`);
      if (!newUrl.endsWith('.webp')) {
        const urlParts = newUrl.split('/');
        const filename = urlParts[urlParts.length - 1];
        const filenameWithoutExt = filename.split('.')[0];
        urlParts[urlParts.length - 1] = `${filenameWithoutExt}.webp`;
        newUrl = urlParts.join('/');
      }
      return newUrl;
    }
  }

  // Если URL не содержит размер, добавляем его
  const urlParts = url.split('/');
  const avatarsIndex = urlParts.findIndex(part => part === 'avatars');
  
  if (avatarsIndex !== -1) {
    // Получаем имя файла
    const filename = urlParts[urlParts.length - 1];
    // Получаем имя файла без расширения
    const filenameWithoutExt = filename.split('.')[0];
    // Формируем новый URL с размером и расширением webp
    const newUrlParts = [...urlParts.slice(0, avatarsIndex + 1), size, `${filenameWithoutExt}.webp`];
    return newUrlParts.join('/');
  }

  return url;
};

/**
 * Проверка валидности URL изображения
 * @param {string} url - URL изображения для проверки
 * @returns {Promise<boolean>} - Промис, который резолвится в true, если URL валиден
 */
export const checkImageUrl = async (url) => {
  if (!url) return false;
  
  try {
    const response = await fetch(url, { method: 'HEAD' });
    return response.ok;
  } catch (error) {
    console.error('Ошибка при проверке URL изображения:', error);
    return false;
  }
};

/**
 * Получение URL изображения-заглушки
 * @param {string} text - Текст для отображения на заглушке
 * @param {Object} options - Дополнительные параметры
 * @returns {string} - URL изображения-заглушки
 */
export const getPlaceholderImage = (text, options = {}) => {
  const {
    width = 200,
    height = 200,
    backgroundColor = '1976d2',
    textColor = 'ffffff'
  } = options;
  
  const letter = text && text.length > 0 ? text[0].toUpperCase() : 'A';
  
  return `https://via.placeholder.com/${width}x${height}/${backgroundColor}/${textColor}?text=${letter}`;
};

/**
 * Получение оптимального размера аватара в зависимости от контекста использования
 * @param {string} context - Контекст использования (card, profile, header, thumbnail)
 * @returns {string} - Оптимальный размер аватара
 */
export const getOptimalAvatarSize = (context) => {
  switch (context) {
    case 'card':
      return 'small';
    case 'profile':
    case 'header':
      return 'medium';
    case 'thumbnail':
      return 'thumbnail';
    case 'large':
      return 'large';
    default:
      return 'medium';
  }
};

/**
 * Преобразование старого пути аватара в новый формат
 * @param {string} avatarPath - Старый путь аватара
 * @param {string} size - Размер аватара (thumbnail, small, medium, large)
 * @returns {string} - Новый путь аватара
 */
export const migrateLegacyAvatarPath = (avatarPath, size = 'medium') => {
  if (!avatarPath) {
    return null;
  }
  
  // Если путь уже в новом формате (содержит размер), возвращаем его
  const validSizes = ['thumbnail', 'small', 'medium', 'large'];
  for (const validSize of validSizes) {
    if (avatarPath.includes(`/${validSize}/`)) {
      // Если размер уже совпадает, возвращаем как есть
      if (validSize === size) {
        return avatarPath;
      }
      // Иначе заменяем размер на запрашиваемый
      return avatarPath.replace(`/${validSize}/`, `/${size}/`);
    }
  }
  
  // Если это внешняя ссылка, возвращаем как есть
  if (avatarPath.startsWith('http://') || avatarPath.startsWith('https://')) {
    return avatarPath;
  }
  
  // Получаем имя файла
  const fileNameWithExt = avatarPath.split('/').pop();
  const fileName = fileNameWithExt.split('.')[0];
  
  // Формируем новый URL с размером и расширением webp
  return `/uploads/avatars/${size}/${fileName}.webp`;
};

// Экспортируем все функции
const mediaUtils = {
  getAvatarUrl,
  checkImageUrl,
  getPlaceholderImage,
  getOptimalAvatarSize,
  migrateLegacyAvatarPath
};

export default mediaUtils;
