/**
 * Форматирует число с разделителями тысяч и суффиксами
 */
export const formatNumber = (num) => {
  if (!num) return '0';
  
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  }
  return num.toString();
};

/**
 * Форматирует дату в относительный формат (например, "2 часа назад")
 */
export const formatRelativeTime = (date) => {
  if (!date) return '';

  const now = new Date();
  const past = new Date(date);
  const diffInSeconds = Math.floor((now - past) / 1000);

  if (diffInSeconds < 60) {
    return 'только что';
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes} ${pluralize(diffInMinutes, 'минуту', 'минуты', 'минут')} назад`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} ${pluralize(diffInHours, 'час', 'часа', 'часов')} назад`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 7) {
    return `${diffInDays} ${pluralize(diffInDays, 'день', 'дня', 'дней')} назад`;
  }

  const diffInWeeks = Math.floor(diffInDays / 7);
  if (diffInWeeks < 4) {
    return `${diffInWeeks} ${pluralize(diffInWeeks, 'неделю', 'недели', 'недель')} назад`;
  }

  const diffInMonths = Math.floor(diffInDays / 30);
  if (diffInMonths < 12) {
    return `${diffInMonths} ${pluralize(diffInMonths, 'месяц', 'месяца', 'месяцев')} назад`;
  }

  const diffInYears = Math.floor(diffInDays / 365);
  return `${diffInYears} ${pluralize(diffInYears, 'год', 'года', 'лет')} назад`;
};

/**
 * Склоняет слово в зависимости от числа
 * @param {number} number - Число
 * @param {string} one - Форма для 1
 * @param {string} few - Форма для 2-4
 * @param {string} many - Форма для 5-20
 * @returns {string} Слово в правильном склонении
 */
export const pluralize = (number, one, few, many) => {
  // Обработка числа для правильного склонения
  const mod10 = number % 10;
  const mod100 = number % 100;

  // Особые случаи для русского языка
  if (mod100 >= 11 && mod100 <= 19) {
    return many;
  }
  
  if (mod10 === 1) {
    return one;
  }
  
  if (mod10 >= 2 && mod10 <= 4) {
    return few;
  }
  
  return many;
};

/**
 * Форматирует число каналов с правильным склонением
 * @param {number} count - Количество каналов
 * @returns {string} Отформатированное число с правильным склонением
 */
export const formatChannelsCount = (count) => {
  const formattedCount = formatNumber(count);
  const word = pluralize(count, 'канал', 'канала', 'каналов');
  return `${formattedCount} ${word}`;
};

/**
 * Преобразует строку в slug для URL
 * @param {string} text - Текст для преобразования
 * @returns {string} Slug для URL
 */
export const slugify = (text) => {
  if (!text) return '';
  
  // Транслитерация кириллицы
  const translitMap = {
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo', 'ж': 'zh',
    'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
    'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'ts',
    'ч': 'ch', 'ш': 'sh', 'щ': 'sch', 'ъ': '', 'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu',
    'я': 'ya'
  };
  
  // Преобразуем текст в нижний регистр и заменяем кириллицу на латиницу
  const transliterated = text.toLowerCase().split('').map(char => {
    return translitMap[char] || char;
  }).join('');
  
  // Заменяем все не буквенно-цифровые символы на дефисы
  return transliterated
    .replace(/[^a-z0-9]+/g, '-')  // Заменяем все не буквенно-цифровые символы на дефисы
    .replace(/^-+|-+$/g, '')      // Удаляем дефисы в начале и конце
    .replace(/-{2,}/g, '-');      // Заменяем множественные дефисы на один
};
