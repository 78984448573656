/**
 * Централизованный реестр иконок для использования в приложении
 * Содержит все доступные иконки Material UI, которые можно использовать в категориях
 */

// Импортируем все иконки, которые будут доступны для выбора
import CategoryIcon from '@mui/icons-material/Category';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ComputerIcon from '@mui/icons-material/Computer';
import BusinessIcon from '@mui/icons-material/Business';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DiamondIcon from '@mui/icons-material/Diamond';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LockIcon from '@mui/icons-material/Lock';
import StarIcon from '@mui/icons-material/Star';
import FaceIcon from '@mui/icons-material/Face';
import WcIcon from '@mui/icons-material/Wc';
import MovieIcon from '@mui/icons-material/Movie';
import HomeIcon from '@mui/icons-material/Home';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import CodeIcon from '@mui/icons-material/Code';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PetsIcon from '@mui/icons-material/Pets';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import FlightIcon from '@mui/icons-material/Flight';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import CasinoIcon from '@mui/icons-material/Casino';
import SpaIcon from '@mui/icons-material/Spa';
import BrushIcon from '@mui/icons-material/Brush';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SearchIcon from '@mui/icons-material/Search';

/**
 * Реестр всех доступных иконок
 * Ключ: название иконки (используется для хранения в БД)
 * Значение: компонент иконки
 */
export const iconRegistry = {
  'CategoryIcon': CategoryIcon,
  'NewspaperIcon': NewspaperIcon,
  'SportsEsportsIcon': SportsEsportsIcon,
  'ComputerIcon': ComputerIcon,
  'BusinessIcon': BusinessIcon,
  'SchoolIcon': SchoolIcon,
  'WorkIcon': WorkIcon,
  'LocalOfferIcon': LocalOfferIcon,
  'WhatshotIcon': WhatshotIcon,
  'FavoriteIcon': FavoriteIcon,
  'LocalFireDepartmentIcon': LocalFireDepartmentIcon,
  'DiamondIcon': DiamondIcon,
  'CameraAltIcon': CameraAltIcon,
  'LockIcon': LockIcon,
  'StarIcon': StarIcon,
  'FaceIcon': FaceIcon,
  'WcIcon': WcIcon,
  'MovieIcon': MovieIcon,
  'HomeIcon': HomeIcon,
  'MusicNoteIcon': MusicNoteIcon,
  'DirectionsCarIcon': DirectionsCarIcon,
  'LocalDiningIcon': LocalDiningIcon,
  'AttachMoneyIcon': AttachMoneyIcon,
  'SportsFootballIcon': SportsFootballIcon,
  'CodeIcon': CodeIcon,
  'ShoppingCartIcon': ShoppingCartIcon,
  'PetsIcon': PetsIcon,
  'ChildCareIcon': ChildCareIcon,
  'FlightIcon': FlightIcon,
  'FitnessCenterIcon': FitnessCenterIcon,
  'CasinoIcon': CasinoIcon,
  'SpaIcon': SpaIcon,
  'BrushIcon': BrushIcon,
  'EmojiEmotionsIcon': EmojiEmotionsIcon,
  'SearchIcon': SearchIcon,
};

/**
 * Группы иконок для удобства выбора
 */
export const iconGroups = [
  { 
    name: 'Популярные', 
    icons: [
      'CategoryIcon', 'StarIcon', 'WhatshotIcon', 'FavoriteIcon', 'HomeIcon', 
      'LocalFireDepartmentIcon', 'DiamondIcon'
    ] 
  },
  { 
    name: 'Медиа', 
    icons: [
      'MovieIcon', 'MusicNoteIcon', 'CameraAltIcon', 'NewspaperIcon'
    ] 
  },
  { 
    name: 'Бизнес', 
    icons: [
      'BusinessIcon', 'WorkIcon', 'AttachMoneyIcon', 'ShoppingCartIcon', 'LocalOfferIcon'
    ] 
  },
  { 
    name: 'Технологии', 
    icons: [
      'ComputerIcon', 'CodeIcon', 'SportsEsportsIcon'
    ] 
  },
  { 
    name: 'Люди', 
    icons: [
      'FaceIcon', 'WcIcon', 'ChildCareIcon', 'EmojiEmotionsIcon'
    ] 
  },
  { 
    name: 'Хобби', 
    icons: [
      'SportsFootballIcon', 'FitnessCenterIcon', 'LocalDiningIcon', 'PetsIcon', 
      'DirectionsCarIcon', 'FlightIcon', 'CasinoIcon', 'SpaIcon', 'BrushIcon'
    ] 
  },
  { 
    name: 'Прочее', 
    icons: [
      'SchoolIcon', 'LockIcon', 'SearchIcon'
    ] 
  }
];

/**
 * Получить компонент иконки по её имени
 * @param {string} iconName - Название иконки
 * @returns {React.ComponentType} - Компонент иконки или SearchIcon, если иконка не найдена
 */
export const getIconByName = (iconName) => {
  return iconRegistry[iconName] || SearchIcon;
};

/**
 * Получить все доступные иконки в виде массива
 * @returns {Array<{name: string, component: React.ComponentType}>} - Массив объектов с именем и компонентом иконки
 */
export const getAllIcons = () => {
  return Object.entries(iconRegistry).map(([name, component]) => ({
    name,
    component
  }));
};

/**
 * Получить иконки по группе
 * @param {string} groupName - Название группы
 * @returns {Array<{name: string, component: React.ComponentType}>} - Массив объектов с именем и компонентом иконки
 */
export const getIconsByGroup = (groupName) => {
  const group = iconGroups.find(g => g.name === groupName);
  if (!group) return [];
  
  return group.icons.map(iconName => ({
    name: iconName,
    component: iconRegistry[iconName]
  }));
};

export default {
  iconRegistry,
  iconGroups,
  getIconByName,
  getAllIcons,
  getIconsByGroup
};
