/**
 * Утилиты для работы с CSRF-токенами
 */
import csrfService from '../services/csrfService';

/**
 * Получает CSRF-токен из cookie
 * @returns {string|null} CSRF-токен или null, если токен не найден
 */
export const getCsrfToken = () => {
  return document.cookie
    .split('; ')
    .find(row => row.startsWith('XSRF-TOKEN='))
    ?.split('=')[1] || null;
};

/**
 * Добавляет CSRF-токен и другие заголовки безопасности к объекту заголовков
 * @param {Object} headers - Объект заголовков
 * @param {boolean} isDeleteOperation - Флаг, указывающий, является ли операция удалением
 * @returns {Object} Обновленный объект заголовков
 */
export const addSecurityHeaders = (headers = {}, isDeleteOperation = false) => {
  const csrfToken = getCsrfToken();
  const updatedHeaders = { ...headers };
  
  if (csrfToken) {
    updatedHeaders['X-CSRF-Token'] = csrfToken;
  }
  
  if (isDeleteOperation) {
    updatedHeaders['X-Admin-Confirmation'] = 'confirm-delete';
  }
  
  return updatedHeaders;
};

/**
 * Проверяет, требуется ли дополнительное подтверждение для операции
 * @param {string} method - HTTP метод (GET, POST, PUT, DELETE, PATCH)
 * @param {string} url - URL запроса
 * @returns {boolean} Требуется ли подтверждение
 */
export const requiresConfirmation = (method, url) => {
  // Операции удаления всегда требуют подтверждения
  if (method === 'DELETE') {
    return true;
  }
  
  // Некоторые PATCH операции также требуют подтверждения
  if (method === 'PATCH') {
    const sensitivePatterns = [
      /\/api\/channels\/\w+\/moderation-status/,
      /\/api\/channels\/\w+\/premium/,
      /\/api\/users\/\w+\/toggle-status/,
      /\/api\/users\/\w+\/toggle-admin/,
      /\/api\/questions\/\w+\/status/
    ];
    
    return sensitivePatterns.some(pattern => pattern.test(url));
  }
  
  return false;
};

/**
 * Обновляет CSRF-токен
 * @returns {Promise<string|null>} Промис с новым CSRF-токеном или null в случае ошибки
 */
export const refreshCsrfToken = async () => {
  try {
    // Используем сервис для обновления CSRF-токена
    await csrfService.refreshCsrfToken();
    
    // Получаем CSRF-токен из cookie
    const token = document.cookie
      .split('; ')
      .find(row => row.startsWith('XSRF-TOKEN='))
      ?.split('=')[1];
    
    if (token) {
      // Сохраняем токен в localStorage
      localStorage.setItem('csrfToken', token);
      return token;
    }
    
    return null;
  } catch (error) {
    // Не выводим ошибку в консоль, если это ошибка сети или таймаута
    // Это нормальная ситуация, когда пользователь не авторизован
    return null;
  }
};
