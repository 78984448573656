/**
 * Централизованный сервис для кэширования данных с поддержкой разных типов хранилищ
 */

// Префикс для всех ключей кэша
const CACHE_PREFIX = 'telehunt_cache_';

// Время жизни кэша по умолчанию (в секундах)
const DEFAULT_TTL = 60 * 60; // 1 час

// Предопределенные TTL для разных типов данных (в секундах)
const CACHE_TTL = {
  // Данные каналов
  CHANNEL: 30 * 60, // 30 минут
  CHANNEL_RECENT: 5 * 60, // 5 минут
  CHANNEL_POPULAR: 15 * 60, // 15 минут
  CHANNEL_RECOMMENDED: 10 * 60, // 10 минут
  
  // Данные страниц
  HOME_PAGE: 60 * 60, // 1 час
  CATEGORY_PAGE: 30 * 60, // 30 минут
  SEARCH_RESULTS: 10 * 60, // 10 минут
  
  // Пользовательские данные
  USER_PREFERENCES: 24 * 60 * 60, // 24 часа
  
  // Метаданные
  SEO: 2 * 60 * 60, // 2 часа
  CHANNEL_SEO: 60 * 60, // 1 час
  CATEGORY_SEO: 60 * 60, // 1 час
  
  // Категории
  CATEGORIES: 60 * 60, // 1 час
  POPULAR_CATEGORIES: 30 * 60, // 30 минут
  CATALOG_DATA: 15 * 60, // 15 минут
};

// Типы хранилищ
const STORAGE_TYPES = {
  LOCAL: 'localStorage',
  SESSION: 'sessionStorage',
  MEMORY: 'memory'
};

// In-memory кэш
const memoryCache = new Map();

/**
 * Получить хранилище по типу
 * @param {string} storageType - Тип хранилища (localStorage, sessionStorage, memory)
 * @returns {Object} - Объект хранилища
 */
const getStorage = (storageType = STORAGE_TYPES.LOCAL) => {
  switch (storageType) {
    case STORAGE_TYPES.LOCAL:
      return localStorage;
    case STORAGE_TYPES.SESSION:
      return sessionStorage;
    case STORAGE_TYPES.MEMORY:
      return {
        getItem: (key) => memoryCache.get(key) || null,
        setItem: (key, value) => memoryCache.set(key, value),
        removeItem: (key) => memoryCache.delete(key),
        clear: () => memoryCache.clear(),
        keys: () => Array.from(memoryCache.keys())
      };
    default:
      return localStorage;
  }
};

/**
 * Сохраняет данные в кэш
 * @param {string} key - Ключ для сохранения данных
 * @param {any} data - Данные для сохранения
 * @param {number|string} ttl - Время жизни кэша в секундах или предопределенный тип из CACHE_TTL
 * @param {string} storageType - Тип хранилища (localStorage, sessionStorage, memory)
 * @returns {boolean} - Успешность операции
 */
export const setCache = (key, data, ttl = DEFAULT_TTL, storageType = STORAGE_TYPES.LOCAL) => {
  try {
    // Определяем TTL
    let actualTtl = ttl;
    if (typeof ttl === 'string' && CACHE_TTL[ttl]) {
      actualTtl = CACHE_TTL[ttl];
    } else if (typeof ttl !== 'number') {
      actualTtl = DEFAULT_TTL;
    }
    
    const cacheKey = CACHE_PREFIX + key;
    const cacheData = {
      data,
      expiry: Date.now() + (actualTtl * 1000),
      storageType
    };
    
    const storage = getStorage(storageType);
    storage.setItem(cacheKey, JSON.stringify(cacheData));
    
    return true;
  } catch (error) {
    console.error('Ошибка при сохранении данных в кэш:', error);
    return false;
  }
};

/**
 * Получает данные из кэша
 * @param {string} key - Ключ для получения данных
 * @param {string} storageType - Тип хранилища (localStorage, sessionStorage, memory)
 * @returns {any|null} - Данные из кэша или null, если данные не найдены или устарели
 */
export const getCache = (key, storageType = STORAGE_TYPES.LOCAL) => {
  try {
    const cacheKey = CACHE_PREFIX + key;
    const storage = getStorage(storageType);
    const cachedItem = storage.getItem(cacheKey);
    
    if (!cachedItem) {
      return null;
    }
    
    const { data, expiry, storageType: itemStorageType } = JSON.parse(cachedItem);
    
    // Если хранилище в кэше отличается от запрошенного, пробуем получить из правильного
    if (itemStorageType && itemStorageType !== storageType) {
      return getCache(key, itemStorageType);
    }
    
    // Проверяем, не истек ли срок действия кэша
    if (Date.now() > expiry) {
      // Если срок истек, удаляем кэш
      storage.removeItem(cacheKey);
      return null;
    }
    
    return data;
  } catch (error) {
    console.error('Ошибка при получении данных из кэша:', error);
    return null;
  }
};

/**
 * Удаляет данные из кэша
 * @param {string} key - Ключ для удаления данных
 * @param {string} storageType - Тип хранилища (localStorage, sessionStorage, memory)
 * @returns {boolean} - Успешность операции
 */
export const removeCache = (key, storageType = STORAGE_TYPES.LOCAL) => {
  try {
    const cacheKey = CACHE_PREFIX + key;
    const storage = getStorage(storageType);
    storage.removeItem(cacheKey);
    return true;
  } catch (error) {
    console.error('Ошибка при удалении данных из кэша:', error);
    return false;
  }
};

/**
 * Очищает весь кэш приложения
 * @param {string} storageType - Тип хранилища (localStorage, sessionStorage, memory)
 * @returns {boolean} - Успешность операции
 */
export const clearAllCache = (storageType = STORAGE_TYPES.LOCAL) => {
  try {
    const storage = getStorage(storageType);
    
    if (storageType === STORAGE_TYPES.MEMORY) {
      storage.clear();
      return true;
    }
    
    const keys = Object.keys(storage);
    keys.forEach(key => {
      if (key.startsWith(CACHE_PREFIX)) {
        storage.removeItem(key);
      }
    });
    
    return true;
  } catch (error) {
    console.error('Ошибка при очистке кэша:', error);
    return false;
  }
};

/**
 * Обновляет время жизни кэша
 * @param {string} key - Ключ для обновления времени жизни
 * @param {number|string} ttl - Время жизни кэша в секундах или предопределенный тип из CACHE_TTL
 * @param {string} storageType - Тип хранилища (localStorage, sessionStorage, memory)
 * @returns {boolean} - Успешность операции
 */
export const refreshCache = (key, ttl = DEFAULT_TTL, storageType = STORAGE_TYPES.LOCAL) => {
  try {
    // Определяем TTL
    let actualTtl = ttl;
    if (typeof ttl === 'string' && CACHE_TTL[ttl]) {
      actualTtl = CACHE_TTL[ttl];
    } else if (typeof ttl !== 'number') {
      actualTtl = DEFAULT_TTL;
    }
    
    const cacheKey = CACHE_PREFIX + key;
    const storage = getStorage(storageType);
    const cachedItem = storage.getItem(cacheKey);
    
    if (!cachedItem) {
      return false;
    }
    
    const { data, storageType: itemStorageType } = JSON.parse(cachedItem);
    
    // Если хранилище в кэше отличается от запрошенного, пробуем обновить в правильном
    if (itemStorageType && itemStorageType !== storageType) {
      return refreshCache(key, ttl, itemStorageType);
    }
    
    // Обновляем время жизни
    const cacheData = {
      data,
      expiry: Date.now() + (actualTtl * 1000),
      storageType
    };
    
    storage.setItem(cacheKey, JSON.stringify(cacheData));
    return true;
  } catch (error) {
    console.error('Ошибка при обновлении времени жизни кэша:', error);
    return false;
  }
};

/**
 * Проверяет наличие данных в кэше
 * @param {string} key - Ключ для проверки
 * @param {string} storageType - Тип хранилища (localStorage, sessionStorage, memory)
 * @returns {boolean} - Наличие данных в кэше
 */
export const hasCache = (key, storageType = STORAGE_TYPES.LOCAL) => {
  return getCache(key, storageType) !== null;
};

// Экспортируем константы для использования в других модулях
export { CACHE_TTL, STORAGE_TYPES };
