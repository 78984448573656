/**
 * Сервис для работы с URL каналов, категорий и других сущностей
 * Централизованно управляет формированием и обработкой URL
 * 
 * ВАЖНО: При внесении изменений в этот файл необходимо синхронизировать 
 * аналогичные изменения в backend/services/urlService.js
 * 
 * @module urlService
 */

import { URL_TYPES, URL_PATTERNS, URL_PREFIXES } from '../constants/urlTypes';

/**
 * Извлекает идентификатор из ссылки на канал
 * @param {string} link - Ссылка на канал в формате https://t.me/username или https://t.me/joinchat/code
 * @returns {string|null} Идентификатор канала или null, если не удалось извлечь
 */
export const extractChannelIdentifier = (link) => {
  if (!link) return null;
  
  try {
    // Проверяем, является ли ссылка приватной
    if (link.includes('/joinchat/') || link.includes('/+')) {
      // Для приватных ссылок извлекаем идентификатор после /joinchat/ или /+
      const privateMatch = link.match(/\/(?:joinchat\/|\+)([a-zA-Z0-9_-]+)/);
      if (privateMatch && privateMatch[1]) {
        return privateMatch[1];
      }
    }
    
    // Для обычных ссылок извлекаем username
    const match = link.match(URL_PATTERNS.TELEGRAM_LINK);
    if (match && match[1]) {
      return match[1];
    }
    
    console.warn(`Не удалось извлечь идентификатор из ссылки: ${link}`);
    return null;
  } catch (error) {
    console.error('Ошибка при извлечении идентификатора из ссылки:', error);
    return null;
  }
};

/**
 * Проверяет, является ли канал приватным по его ссылке
 * @param {string|Object} channelOrLink - Объект канала или ссылка
 * @returns {boolean} true, если канал приватный
 */
export const isPrivateChannel = (channelOrLink) => {
  const link = typeof channelOrLink === 'string' 
    ? channelOrLink 
    : channelOrLink?.link;
    
  if (!link) return false;
  
  return URL_PATTERNS.PRIVATE_LINK.test(link) || link.includes('/joinchat/') || link.includes('/+');
};

/**
 * Определяет тип идентификатора из URL-пути
 * @param {string} path - Путь URL
 * @returns {Object} Тип и значение идентификатора
 */
export const parseChannelPath = (path) => {
  if (!path) return {};
  
  // Убираем префикс /channel/
  const identifier = path.replace(/^\/channel\//, '');
  
  if (identifier.startsWith('@')) {
    return { 
      type: URL_TYPES.USERNAME,
      value: identifier.substring(1)
    };
  }
  
  if (identifier.startsWith('id/')) {
    return { 
      type: URL_TYPES.ID,
      value: identifier.substring(3)
    };
  }
  
  return { 
    type: URL_TYPES.IDENTIFIER,
    value: identifier
  };
};

/**
 * Получить канонический URL для канала
 * @param {Object} channel - Объект канала
 * @returns {string} Канонический URL канала
 */
export const getChannelUrl = (channel) => {
  if (!channel) {
    console.warn('Попытка получить URL для несуществующего канала');
    return '/channels';
  }
  
  // Для публичных каналов с username используем @username
  if (channel.username && !isPrivateChannel(channel)) {
    return `${URL_PREFIXES.USERNAME}${channel.username}`;
  }
  
  // Извлекаем идентификатор из ссылки
  const identifier = extractChannelIdentifier(channel.link);
  
  // Если не удалось извлечь идентификатор, используем ID
  if (!identifier) {
    if (channel._id) {
      return `${URL_PREFIXES.ID}${channel._id}`;
    }
    console.warn('Не удалось сформировать URL для канала:', channel);
    return '/channels';
  }
  
  // Для приватных каналов используем идентификатор без @
  return `${URL_PREFIXES.CHANNEL}${identifier}`;
};

/**
 * Получить полный URL для канала
 * @param {Object} channel - Объект канала
 * @param {string} baseUrl - Базовый URL сайта
 * @returns {string} Полный URL канала
 */
export const getFullChannelUrl = (channel, baseUrl = window.location.origin) => {
  if (!channel) return baseUrl + '/channels';
  
  const path = getChannelUrl(channel);
  return baseUrl + path;
};

/**
 * Получить URL для категории
 * @param {Object} category - Объект категории
 * @returns {string} URL категории
 */
export const getCategoryUrl = (category) => {
  if (!category) return '/categories';
  
  if (category.slug) {
    return `/category/${category.slug}`;
  }
  
  return `/category/${category._id}`;
};

/**
 * Получить полный URL для категории
 * @param {Object} category - Объект категории
 * @param {string} baseUrl - Базовый URL сайта
 * @returns {string} Полный URL категории
 */
export const getFullCategoryUrl = (category, baseUrl = window.location.origin) => {
  if (!category) return baseUrl + '/categories';
  
  const path = getCategoryUrl(category);
  return baseUrl + path;
};

/**
 * Получить URL для статьи
 * @param {Object} article - Объект статьи
 * @returns {string} URL статьи
 */
export const getArticleUrl = (article) => {
  if (!article) return '/blog';
  
  if (article.slug) {
    return `/blog/${article.slug}`;
  }
  
  return `/blog/${article._id}`;
};

/**
 * Получить полный URL для статьи
 * @param {Object} article - Объект статьи
 * @param {string} baseUrl - Базовый URL сайта
 * @returns {string} Полный URL статьи
 */
export const getFullArticleUrl = (article, baseUrl = window.location.origin) => {
  if (!article) return baseUrl + '/blog';
  
  const path = getArticleUrl(article);
  return baseUrl + path;
};

/**
 * Получить параметры для API запроса на основе URL
 * @param {string} path - Путь URL
 * @returns {Object} Параметры для API запроса
 */
export const getChannelRequestParams = (path) => {
  return parseChannelPath(path);
};

/**
 * Получить API endpoint для запроса канала
 * @param {Object} params - Параметры запроса
 * @returns {string} API endpoint
 */
export const getChannelApiEndpoint = (params) => {
  if (!params || !params.type || !params.value) return '';
  
  switch (params.type) {
    case URL_TYPES.USERNAME:
      return `/api/channels/username/${params.value}`;
    case URL_TYPES.ID:
      return `/api/channels/${params.value}`;
    case URL_TYPES.IDENTIFIER:
      return `/api/channels/identifier/${params.value}`;
    default:
      return '';
  }
};

/**
 * Проверить, является ли URL каноническим для данного канала
 * @param {Object} channel - Объект канала
 * @param {string} currentPath - Текущий путь
 * @returns {boolean} true, если URL канонический
 */
export const isCanonicalUrl = (channel, currentPath) => {
  if (!channel || !currentPath) return false;
  
  const canonicalUrl = getChannelUrl(channel);
  return canonicalUrl === currentPath;
};

/**
 * Получить канонический URL для текущего пути
 * @param {string} path - Текущий путь
 * @param {Object} channel - Объект канала
 * @returns {string} Канонический URL
 */
export const getCanonicalUrl = (path, channel) => {
  if (!channel) return path;
  
  // Если путь уже канонический, возвращаем его
  if (isCanonicalUrl(channel, path)) {
    return path;
  }
  
  // Иначе возвращаем канонический URL
  return getChannelUrl(channel);
};

/**
 * Получить мета-теги для канала
 * @param {Object} channel - Объект канала
 * @param {string} baseUrl - Базовый URL сайта
 * @returns {Object} Мета-теги для SEO
 */
export const getChannelMetaTags = (channel, baseUrl = window.location.origin) => {
  if (!channel) return {};
  
  const canonicalUrl = getFullChannelUrl(channel, baseUrl);
  const title = `${channel.name} - Телеграм канал`;
  const description = channel.description 
    ? (channel.description.length > 160 
        ? channel.description.substring(0, 157) + '...' 
        : channel.description)
    : `Телеграм канал ${channel.name}`;
  
  return {
    title,
    description,
    canonicalUrl,
    ogTitle: title,
    ogDescription: description,
    ogUrl: canonicalUrl,
    ogImage: channel.avatar ? `${baseUrl}${channel.avatar}` : null
  };
};
