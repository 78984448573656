import { getCache, setCache, CACHE_TTL, STORAGE_TYPES } from './cacheService';
import { channelService } from './channelService';
import { getHomeSeo } from './seoApi';

// Создаем событие для оповещения об обновлении данных
const DATA_UPDATED_EVENT = 'home_data_updated';

/**
 * Сервис для работы с данными домашней страницы
 */
export const homeService = {
  /**
   * Получить все данные для главной страницы
   * @returns {Promise<Object>} - Объект с данными для главной страницы
   */
  async getHomeData() {
    // Проверяем кэш
    const cachedData = getCache('home_data');
    if (cachedData) {
      console.log('[INFO] Используются кэшированные данные для главной страницы');
      
      // Обновляем кэш в фоне, но не блокируем основной поток
      setTimeout(() => this.refreshCacheInBackground(), 100);
      
      return cachedData;
    }
    
    try {
      // Загружаем только критически важные данные сначала
      const criticalData = await this.getCriticalData();
      
      // Запускаем загрузку остальных данных в фоне
      this.loadRemainingDataAsync(criticalData);
      
      return criticalData;
    } catch (error) {
      console.error('[Ошибка] homeService.getHomeData - Ошибка при загрузке данных:', error);
      
      // В случае ошибки возвращаем пустые массивы
      return {
        recent: [],
        popular: [],
        recommended: [],
        seo: {}
      };
    }
  },

  /**
   * Обновление кэша в фоне без блокировки основного потока
   */
  refreshCacheInBackground() {
    // Используем requestIdleCallback для выполнения в свободное время
    const idleCallback = window.requestIdleCallback || ((cb) => setTimeout(cb, 1));
    
    idleCallback(() => {
      // Загружаем новые данные, но не блокируем UI
      this.getCriticalData(true)
        .then(data => {
          console.log('[INFO] Кэш данных главной страницы обновлен в фоне');
        })
        .catch(error => {
          console.error('[Ошибка] Не удалось обновить кэш в фоне:', error);
        });
    });
  },

  /**
   * Получить критически важные данные для главной страницы
   * @param {boolean} forceRefresh - Принудительно обновить данные, игнорируя кэш
   * @returns {Promise<Object>} - Объект с критически важными данными
   */
  async getCriticalData(forceRefresh = false) {
    // Проверяем кэш, если не требуется принудительное обновление
    if (!forceRefresh) {
      const cachedData = getCache('home_critical_data', STORAGE_TYPES.MEMORY);
      if (cachedData) {
        console.log('[INFO] Используются кэшированные критические данные для главной страницы');
        return cachedData;
      }
    }
    
    try {
      // Создаем запросы с таймаутами
      const createRequestWithTimeout = (promise, timeoutMs = 3000) => {
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('Таймаут запроса')), timeoutMs);
        });
        return Promise.race([promise, timeoutPromise]);
      };
      
      // Запускаем запросы параллельно
      const [popularData, seoData] = await Promise.all([
        createRequestWithTimeout(channelService.getPopular(), 3000)
          .catch(error => {
            console.error('[Ошибка] Не удалось загрузить популярные каналы:', error);
            return { data: [] };
          }),
        createRequestWithTimeout(getHomeSeo(), 2000)
          .catch(error => {
            console.error('[Ошибка] Не удалось загрузить SEO-метаданные:', error);
            return {};
          })
      ]);
      
      // Формируем объект с данными
      const criticalData = {
        popular: popularData.data || [],
        recent: [], // Будет загружено позже
        recommended: [], // Будет загружено позже
        seo: seoData || {}
      };
      
      // Кэшируем данные
      setCache('home_critical_data', criticalData, CACHE_TTL.FIVE_MINUTES, STORAGE_TYPES.MEMORY);
      
      return criticalData;
    } catch (error) {
      console.error('[Ошибка] homeService.getCriticalData - Ошибка при загрузке критических данных:', error);
      
      // В случае ошибки возвращаем пустые массивы
      return {
        popular: [],
        recent: [],
        recommended: [],
        seo: {}
      };
    }
  },

  /**
   * Асинхронно загрузить оставшиеся данные в фоне
   * @param {Object} existingData - Уже загруженные данные
   */
  loadRemainingDataAsync(existingData = {}) {
    // Используем setTimeout с нулевой задержкой, чтобы не блокировать основной поток
    setTimeout(async () => {
      try {
        const fullData = await this.loadRemainingData(existingData);
        
        // Кэшируем полные данные
        setCache('home_data', fullData, CACHE_TTL.FIFTEEN_MINUTES);
        
        // Отправляем событие обновления данных
        this.dispatchDataUpdatedEvent(fullData);
        
        console.log('[INFO] Загрузка оставшихся данных для главной страницы завершена');
      } catch (error) {
        console.error('[Ошибка] Не удалось загрузить оставшиеся данные:', error);
      }
    }, 0);
  },

  /**
   * Загрузить оставшиеся данные после первоначальной загрузки
   * @param {Object} existingData - Уже загруженные данные
   * @returns {Promise<Object>} - Объект с полными данными
   */
  async loadRemainingData(existingData = {}) {
    try {
      // Создаем запросы с таймаутами
      const createRequestWithTimeout = (promise, timeoutMs = 15000) => {
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error('Таймаут запроса')), timeoutMs);
        });
        return Promise.race([promise, timeoutPromise]);
      };
      
      // Запускаем запросы параллельно
      const [recentData, recommendedData] = await Promise.all([
        createRequestWithTimeout(channelService.getRecent(), 15000)
          .catch(error => {
            console.error('[Ошибка] Не удалось загрузить недавние каналы:', error);
            return { data: [] };
          }),
        createRequestWithTimeout(channelService.getRecommended(), 15000)
          .catch(error => {
            console.error('[Ошибка] Не удалось загрузить рекомендуемые каналы:', error);
            return { data: [] };
          })
      ]);
      
      // Объединяем существующие и новые данные
      const fullData = {
        ...existingData,
        recent: recentData.data || [],
        recommended: recommendedData.data || []
      };
      
      return fullData;
    } catch (error) {
      console.error('[Ошибка] homeService.loadRemainingData - Ошибка при загрузке оставшихся данных:', error);
      
      // Возвращаем существующие данные с пустыми массивами для отсутствующих данных
      return {
        ...existingData,
        recent: existingData.recent || [],
        recommended: existingData.recommended || []
      };
    }
  },
  
  /**
   * Отправить событие обновления данных
   * @param {Object} data - Обновленные данные
   */
  dispatchDataUpdatedEvent(data) {
    // Создаем и отправляем пользовательское событие
    const event = new CustomEvent(DATA_UPDATED_EVENT, { detail: data });
    window.dispatchEvent(event);
    console.log('[INFO] Отправлено событие обновления данных');
  },
  
  /**
   * Подписаться на событие обновления данных
   * @param {Function} callback - Функция обратного вызова
   */
  subscribeToDataUpdates(callback) {
    window.addEventListener(DATA_UPDATED_EVENT, (event) => {
      callback(event.detail);
    });
  },
  
  /**
   * Отписаться от события обновления данных
   * @param {Function} callback - Функция обратного вызова
   */
  unsubscribeFromDataUpdates(callback) {
    window.removeEventListener(DATA_UPDATED_EVENT, callback);
  }
};
