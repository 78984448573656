/**
 * Сервис для работы с категориями
 * Централизованно управляет данными категорий с поддержкой кэширования
 */
import axios from 'axios';
import { slugify } from '../utils/stringUtils';
import { getCache, setCache, CACHE_TTL } from './cacheService';
import { getCategorySeo } from './seoApi';

// Экспортируем функцию getCategorySeo из seoApi.js для удобства использования
export { getCategorySeo };

/**
 * Класс для работы с категориями
 */
class CategoryService {
  constructor() {
    this.categories = [];
    this.isLoaded = false;
    this.loadingPromise = null;
    this.loadError = null;
    
    // Инициализация при создании экземпляра
    this.init();
  }
  
  // Инициализация сервиса
  async init() {
    await this.loadCategories();
  }
  
  /**
   * Загрузка категорий с сервера
   * @returns {Promise<Array>} Массив категорий
   * @throws {Error} Если не удалось загрузить категории
   */
  async loadCategories() {
    // Если категории уже загружаются, возвращаем существующий промис
    if (this.loadingPromise) {
      return this.loadingPromise;
    }

    // Проверяем кэш
    const cachedCategories = getCache('categories');
    if (cachedCategories) {
      console.log('[DEBUG] CategoryService.loadCategories - Категории загружены из кэша');
      this.categories = cachedCategories;
      this.isLoaded = true;
      return this.categories;
    }

    console.log('[DEBUG] CategoryService.loadCategories - Загрузка категорий с сервера');
    
    // Создаем промис для загрузки категорий
    this.loadingPromise = new Promise(async (resolve, reject) => {
      try {
        // Загружаем все категории с сервера
        const response = await axios.get('/api/categories');
        
        // Обрабатываем ответ и формируем массив категорий
        const categories = response.data.map(category => ({
          ...category,
          // Добавляем slug, если он не указан
          slug: category.slug || slugify(category.name)
        }));
        
        // Сохраняем в кэш, используя предопределенный TTL для категорий
        setCache('categories', categories, CACHE_TTL.CATEGORIES);
        
        // Обновляем состояние класса
        this.categories = categories;
        this.isLoaded = true;
        this.loadingPromise = null;
        
        console.log('[DEBUG] CategoryService.loadCategories - Категории успешно загружены:', categories.length);
        resolve(categories);
      } catch (error) {
        console.error('[ERROR] CategoryService.loadCategories - Ошибка при загрузке категорий:', error);
        
        // Сбрасываем состояние загрузки
        this.loadingPromise = null;
        reject(error);
      }
    });
    
    return this.loadingPromise;
  }
  
  /**
   * Получить URL категории
   * @param {string} categoryName - Название категории
   * @returns {string} URL категории
   */
  getCategoryUrl(categoryName) {
    if (!categoryName || typeof categoryName !== 'string') {
      console.warn('[WARN] categoryService - getCategoryUrl: categoryName должен быть строкой');
      return '/category/all';
    }
    
    const category = this.categories.find(cat => cat.name === categoryName);
    if (category) {
      return `/category/${category.slug}`;
    }
    return `/category/${slugify(categoryName)}`;
  }
  
  /**
   * Получить slug категории
   * @param {string} categoryName - Название категории
   * @returns {string} Slug категории
   */
  getCategorySlug(categoryName) {
    if (!categoryName || typeof categoryName !== 'string') {
      console.warn('[WARN] categoryService - getCategorySlug: categoryName должен быть строкой');
      return 'all';
    }
    
    const category = this.categories.find(cat => cat.name === categoryName);
    if (category) {
      return category.slug;
    }
    return slugify(categoryName);
  }

  /**
   * Получить название категории по slug
   * @param {string} slug - Slug категории
   * @returns {string|null} Название категории или null, если не найдена
   */
  getCategoryNameBySlug(slug) {
    if (!slug || typeof slug !== 'string') {
      console.warn('[WARN] categoryService - getCategoryNameBySlug: slug должен быть строкой');
      return null;
    }
    
    const category = this.categories.find(cat => cat.slug === slug);
    return category ? category.name : null;
  }

  /**
   * Проверить существование категории
   * @param {string} categoryName - Название категории
   * @returns {boolean} true, если категория существует
   */
  categoryExists(categoryName) {
    if (!categoryName || typeof categoryName !== 'string') {
      console.warn('[WARN] categoryService - categoryExists: categoryName должен быть строкой');
      return false;
    }
    
    return this.categories.some(cat => cat.name === categoryName);
  }
  
  /**
   * Получить категорию по slug
   * @param {string} slug - Slug категории
   * @returns {Promise<Object|null>} Объект категории или null, если не найдена
   */
  async getCategoryBySlug(slug) {
    try {
      if (!slug) {
        console.error('[ERROR] CategoryService.getCategoryBySlug - не указан slug категории');
        return null;
      }
      
      // Проверяем кэш
      const cacheKey = `category_${slug}`;
      const cachedCategory = getCache(cacheKey);
      
      if (cachedCategory) {
        console.log('[DEBUG] CategoryService.getCategoryBySlug - Категория загружена из кэша:', slug);
        return cachedCategory;
      }
      
      console.log('[DEBUG] CategoryService.getCategoryBySlug - Загрузка категории:', slug);
      
      // Загружаем данные категории с сервера
      const response = await axios.get(`/api/categories/${slug}`);
      
      // Сохраняем в кэш, используя предопределенный TTL для категорий
      setCache(cacheKey, response.data, CACHE_TTL.CATEGORIES);
      
      console.log('[DEBUG] CategoryService.getCategoryBySlug - Категория успешно загружена:', slug);
      return response.data;
    } catch (error) {
      console.error('[ERROR] CategoryService.getCategoryBySlug - Ошибка при получении категории по slug:', slug, error);
      return null;
    }
  }
  
  /**
   * Получить все категории
   * @returns {Array} Массив всех категорий
   */
  getCategories() {
    // Если категории еще не загружены, возвращаем пустой массив
    if (!this.isLoaded) {
      console.log('[DEBUG] CategoryService.getCategories - Категории еще не загружены, возвращаем пустой массив');
      return [];
    }
    return [...this.categories];
  }
  
  /**
   * Получить только активные категории
   * @returns {Array} Массив активных категорий
   */
  getActiveCategories() {
    const categories = this.getCategories();
    return categories.filter(category => category.isActive);
  }
  
  /**
   * Получить категории для отображения в меню
   * @returns {Array} Массив категорий для меню
   */
  getMenuCategories() {
    return this.categories.filter(cat => cat.showInMenu);
  }
  
  /**
   * Получить категории для отображения на главной странице
   * @returns {Array} Массив категорий для главной страницы
   */
  getHomepageCategories() {
    return this.categories.filter(cat => cat.showOnHomepage);
  }
  
  /**
   * Получить категории для взрослых
   * @returns {Array} Массив категорий для взрослых
   */
  getAdultCategories() {
    return this.categories.filter(cat => cat.isAdult);
  }
  
  /**
   * Фильтрация категорий по поисковому запросу
   * @param {string} searchQuery - Поисковый запрос
   * @returns {Array} Массив отфильтрованных категорий
   */
  filterCategories(searchQuery) {
    if (!searchQuery || searchQuery.trim() === '') {
      return this.getActiveCategories();
    }
    
    // Проверяем кэш
    const cacheKey = `filtered_categories_${searchQuery.toLowerCase().trim()}`;
    const cachedResults = getCache(cacheKey);
    
    if (cachedResults) {
      console.log('[DEBUG] CategoryService.filterCategories - Отфильтрованные категории загружены из кэша');
      return cachedResults;
    }
    
    console.log('[DEBUG] CategoryService.filterCategories - Фильтрация категорий по запросу:', searchQuery);
    
    // Приводим запрос к нижнему регистру для сравнения
    const query = searchQuery.toLowerCase().trim();
    
    // Фильтруем активные категории по запросу
    const filteredCategories = this.getActiveCategories().filter(category => {
      // Проверяем совпадение в названии, описании и тегах
      const nameMatch = category.name.toLowerCase().includes(query);
      const descMatch = category.description ? category.description.toLowerCase().includes(query) : false;
      const tagsMatch = category.tags ? category.tags.some(tag => tag.toLowerCase().includes(query)) : false;
      
      return nameMatch || descMatch || tagsMatch;
    });
    
    // Сохраняем результаты в кэш с предопределенным TTL
    setCache(cacheKey, filteredCategories, CACHE_TTL.SEARCH_RESULTS);
    
    console.log('[DEBUG] CategoryService.filterCategories - Найдено категорий:', filteredCategories.length);
    return filteredCategories;
  }
  
  /**
   * Получить популярные категории
   * @param {number} limit - Максимальное количество категорий
   * @returns {Promise<Array>} Массив популярных категорий
   */
  async getPopularCategories(limit = 5) {
    try {
      // Проверяем кэш
      const cacheKey = `popular_categories_${limit}`;
      const cachedCategories = getCache(cacheKey);
      
      if (cachedCategories) {
        console.log('[DEBUG] CategoryService.getPopularCategories - Популярные категории загружены из кэша');
        return cachedCategories;
      }
      
      console.log('[DEBUG] CategoryService.getPopularCategories - Загрузка популярных категорий');
      
      // Загружаем популярные категории с сервера
      const response = await axios.get('/api/categories/popular', {
        params: { limit }
      });
      
      // Обрабатываем ответ и формируем массив популярных категорий
      const popularCategories = response.data.map(category => ({
        ...category,
        isPopular: true
      }));
      
      // Сохраняем в кэш, используя предопределенный TTL для популярных категорий
      setCache(cacheKey, popularCategories, CACHE_TTL.POPULAR_CATEGORIES);
      
      console.log('[DEBUG] CategoryService.getPopularCategories - Популярные категории успешно загружены');
      return popularCategories;
    } catch (error) {
      console.error('[ERROR] CategoryService.getPopularCategories - Ошибка при получении популярных категорий:', error);
      
      // В случае ошибки возвращаем пустой массив
      return [];
    }
  }
  
  /**
   * Получить все данные для страницы каталога
   * @param {string} searchQuery - Поисковый запрос для фильтрации категорий (опционально)
   * @returns {Promise<Object>} - Объект с данными для страницы каталога
   * @throws {Error} Если не удалось загрузить данные
   */
  async getCatalogData(searchQuery = '') {
    try {
      // Формируем ключ для кэша, учитывая поисковый запрос
      const cacheKey = `catalog_data_${searchQuery || 'default'}`;
      
      // Проверяем кэш
      const cachedData = getCache(cacheKey);
      if (cachedData) {
        console.log('[DEBUG] CategoryService.getCatalogData - Данные каталога загружены из кэша');
        return cachedData;
      }
      
      console.log('[DEBUG] CategoryService.getCatalogData - Загрузка данных каталога');
      
      // Параллельно загружаем все необходимые данные
      const [popularCats] = await Promise.all([
        this.getPopularCategories(5) // Получаем популярные категории (топ-5)
      ]);
      
      // Фильтруем категории по поисковому запросу, если он указан
      let filteredCategories = this.categories;
      if (searchQuery && searchQuery.trim() !== '') {
        filteredCategories = this.filterCategories(searchQuery);
      }
      
      // Формируем результат
      const result = {
        categories: filteredCategories,
        popularCategories: popularCats,
      };
      
      // Сохраняем в кэш, используя предопределенный TTL 
      // для результатов поиска используем более короткое время жизни кэша
      const ttl = searchQuery ? CACHE_TTL.SEARCH_RESULTS : CACHE_TTL.CATALOG_DATA;
      setCache(cacheKey, result, ttl);
      
      console.log('[DEBUG] CategoryService.getCatalogData - Данные каталога успешно загружены');
      return result;
    } catch (error) {
      console.error('[ERROR] CategoryService.getCatalogData - Ошибка при получении данных каталога:', error);
      
      // В случае ошибки пробуем вернуть хотя бы базовые данные
      // Если категории не загружены, пробуем загрузить их еще раз
      if (!this.isLoaded) {
        try {
          await this.loadCategories();
        } catch (loadError) {
          // Если не удалось загрузить категории, выбрасываем ошибку
          throw new Error('Не удалось загрузить категории для отображения каталога');
        }
      }
      
      // Если категории загружены, формируем базовый объект с данными
      if (this.isLoaded && this.categories.length > 0) {
        const activeCategories = this.getActiveCategories();
        const allCategory = {
          name: 'Все',
          slug: 'all',
          icon: 'CategoryIcon',
          color: '#2196f3',
          isActive: true,
          isPopular: true,
          channelsCount: activeCategories.reduce((sum, cat) => sum + (cat.channelsCount || 0), 0)
        };
        
        return {
          categories: activeCategories,
          allCategories: this.getCategories(),
          activeCategories: activeCategories,
          filteredCategories: searchQuery ? this.filterCategories(searchQuery) : activeCategories,
          popularCategories: [allCategory, ...activeCategories.filter(cat => cat.isPopular === true).slice(0, 4)],
          seo: null,
          searchQuery: searchQuery,
          error: true
        };
      }
      
      // Если ничего не помогло, выбрасываем ошибку
      throw new Error('Не удалось загрузить данные каталога');
    }
  }

  /**
   * Получить данные для страницы категории
   * @param {string} slug - Slug категории
   * @returns {Promise<Object>} Объект с данными для страницы категории
   */
  async getCategoryData(slug) {
    try {
      if (!slug) {
        console.error('[ERROR] CategoryService.getCategoryData - Не указан slug категории');
        return { category: null, channels: [], seo: {} };
      }
      
      // Формируем ключ для кэша
      const cacheKey = `category_data_${slug}`;
      
      // Проверяем кэш
      const cachedData = getCache(cacheKey);
      if (cachedData) {
        console.log('[DEBUG] CategoryService.getCategoryData - Данные категории загружены из кэша:', slug);
        return cachedData;
      }
      
      console.log('[DEBUG] CategoryService.getCategoryData - Загрузка данных категории:', slug);
      
      // Параллельно загружаем все необходимые данные
      const [category, seoData] = await Promise.all([
        this.getCategoryBySlug(slug),
        getCategorySeo(slug)
      ]);
      
      // Если категория не найдена, возвращаем пустые данные
      if (!category) {
        console.error('[ERROR] CategoryService.getCategoryData - Категория не найдена:', slug);
        return { category: null, channels: [], seo: {} };
      }
      
      // Формируем результат
      const result = {
        category,
        seo: seoData?.data || {}
      };
      
      // Сохраняем в кэш с использованием предопределенного TTL
      setCache(cacheKey, result, CACHE_TTL.CATEGORY_PAGE);
      
      console.log('[DEBUG] CategoryService.getCategoryData - Данные категории успешно загружены:', slug);
      return result;
    } catch (error) {
      console.error('[ERROR] CategoryService.getCategoryData - Ошибка при получении данных категории:', slug, error);
      
      // В случае ошибки возвращаем пустые данные
      return { category: null, channels: [], seo: {} };
    }
  }
}

// Создаем экземпляр сервиса
const categoryServiceInstance = new CategoryService();

// Экспортируем экземпляр по умолчанию
export default categoryServiceInstance;

// Экспортируем вспомогательные функции для удобства использования
export const getCategoryUrl = (categoryName) => categoryServiceInstance.getCategoryUrl(categoryName);
export const getCategorySlug = (categoryName) => categoryServiceInstance.getCategorySlug(categoryName);
export const getCategoryNameBySlug = (slug) => categoryServiceInstance.getCategoryNameBySlug(slug);
export const categoryExists = (categoryName) => categoryServiceInstance.categoryExists(categoryName);
export const filterCategories = (searchQuery) => {
  // Обеспечиваем дополнительную проверку типа для обратной совместимости
  if (searchQuery !== undefined && typeof searchQuery !== 'string') {
    console.warn('[WARN] categoryService - filterCategories: searchQuery должен быть строкой');
    return categoryServiceInstance.getActiveCategories();
  }
  return categoryServiceInstance.filterCategories(searchQuery);
};
export const getPopularCategories = async (limit) => {
  // Проверяем, что limit - число или undefined
  if (limit !== undefined && (typeof limit !== 'number' || isNaN(limit))) {
    console.warn('[WARN] categoryService - getPopularCategories: limit должен быть числом');
    return categoryServiceInstance.getPopularCategories(5);
  }
  return categoryServiceInstance.getPopularCategories(limit);
};
export const getCategoryBySlug = (slug) => categoryServiceInstance.getCategoryBySlug(slug);
export const getCategories = () => categoryServiceInstance.getCategories();
export const getActiveCategories = () => categoryServiceInstance.getActiveCategories();
export const getMenuCategories = () => categoryServiceInstance.getMenuCategories();
export const getHomepageCategories = () => categoryServiceInstance.getHomepageCategories();
export const getAdultCategories = () => categoryServiceInstance.getAdultCategories();
export const getCatalogData = (searchQuery) => categoryServiceInstance.getCatalogData(searchQuery);

/**
 * Получить SEO-метаданные для страницы каталога
 * @param {string} searchQuery - Поисковый запрос для фильтрации категорий (опционально)
 * @returns {Promise<Object>} Объект с SEO-метаданными для страницы каталога
 */
export const getCatalogSeo = async (searchQuery = '') => {
  try {
    // Формируем ключ для кэша
    const cacheKey = `catalog_seo_${searchQuery || 'default'}`;
    
    // Проверяем кэш
    const cachedData = getCache(cacheKey);
    if (cachedData) {
      console.log('[DEBUG] getCatalogSeo - SEO-метаданные для страницы каталога загружены из кэша');
      return cachedData;
    }
    
    console.log('[DEBUG] getCatalogSeo - Загрузка SEO-метаданных для страницы каталога');
    
    // Загружаем SEO-метаданные с сервера
    const params = searchQuery ? { search: searchQuery } : {};
    const response = await axios.get('/api/seo/catalog', { params });
    
    // Сохраняем в кэш с использованием предопределенного TTL
    setCache(cacheKey, response.data, CACHE_TTL.SEO);
    
    console.log('[DEBUG] getCatalogSeo - SEO-метаданные для страницы каталога успешно загружены');
    return response.data;
  } catch (error) {
    console.error('[ERROR] getCatalogSeo - Ошибка при получении SEO-метаданных для страницы каталога:', error);
    
    // В случае ошибки возвращаем пустой объект
    return {};
  }
};
