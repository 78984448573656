/**
 * Утилита для инициализации CSRF-токена
 */
import { getCsrfToken, refreshCsrfToken } from '../services/csrfService';

// Время последнего обновления токена
let lastTokenRefresh = 0;
// Интервал обновления для неавторизованных пользователей (60 минут)
const GUEST_REFRESH_INTERVAL = 60 * 60 * 1000;
// Интервал обновления для авторизованных пользователей (30 минут)
const AUTH_REFRESH_INTERVAL = 30 * 60 * 1000;
// Интервал обновления для админов (15 минут)
const ADMIN_REFRESH_INTERVAL = 15 * 60 * 1000;

/**
 * Инициализирует CSRF-токен, запрашивая его с сервера
 * @returns {Promise<void>}
 */
export const initializeCsrf = async () => {
  try {
    // Делаем запрос к специальному эндпоинту для получения CSRF-токена
    await getCsrfToken();
    lastTokenRefresh = Date.now();
    console.log('CSRF-токен инициализирован');
  } catch (error) {
    console.error('Ошибка при инициализации CSRF-токена:', error);
    // Не выбрасываем ошибку, чтобы приложение могло продолжить работу
  }
};

/**
 * Проверяет, нужно ли обновлять CSRF-токен
 * @param {boolean} isAuthenticated - Авторизован ли пользователь
 * @param {boolean} isAdmin - Является ли пользователь администратором
 * @param {boolean} forceRefresh - Принудительное обновление токена
 * @returns {boolean} Нужно ли обновлять токен
 */
const shouldRefreshToken = (isAuthenticated, isAdmin, forceRefresh = false) => {
  if (forceRefresh) return true;
  
  const now = Date.now();
  const timeSinceLastRefresh = now - lastTokenRefresh;
  
  if (isAdmin && timeSinceLastRefresh > ADMIN_REFRESH_INTERVAL) {
    return true;
  }
  
  if (isAuthenticated && timeSinceLastRefresh > AUTH_REFRESH_INTERVAL) {
    return true;
  }
  
  if (!isAuthenticated && timeSinceLastRefresh > GUEST_REFRESH_INTERVAL) {
    return true;
  }
  
  return false;
};

/**
 * Обновляет CSRF-токен при необходимости
 * @param {boolean} isAuthenticated - Авторизован ли пользователь
 * @param {boolean} isAdmin - Является ли пользователь администратором
 * @param {boolean} forceRefresh - Принудительное обновление токена
 * @returns {Promise<void>}
 */
export const refreshCsrfTokenHandler = async (isAuthenticated = false, isAdmin = false, forceRefresh = false) => {
  try {
    // Проверяем, нужно ли обновлять токен
    if (!shouldRefreshToken(isAuthenticated, isAdmin, forceRefresh)) {
      return;
    }
    
    // Обновляем CSRF-токен через специальный сервис
    const response = await refreshCsrfToken();
    
    // Если запрос успешен
    if (response && response.success) {
      lastTokenRefresh = Date.now();
      console.log('CSRF-токен обновлен');
    } else {
      console.warn('Не удалось обновить CSRF-токен, но приложение продолжит работу');
    }
  } catch (error) {
    // Не выводим ошибки в консоль - это нормальная ситуация при отсутствии авторизации
  }
};
