/**
 * Утилиты для работы со строками
 */

/**
 * Преобразует строку в slug (URL-совместимую строку)
 * @param {string} str - Исходная строка
 * @returns {string} - Преобразованная строка (slug)
 */
export const slugify = (str) => {
  if (!str) return '';
  
  // Транслитерация кириллицы
  const translitMap = {
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo', 
    'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 
    'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 
    'ф': 'f', 'х': 'h', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'sch', 'ъ': '', 
    'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu', 'я': 'ya'
  };
  
  return str
    .toLowerCase()
    .trim()
    // Транслитерация кириллицы
    .replace(/[а-яё]/g, char => translitMap[char] || char)
    // Замена пробелов и специальных символов на дефисы
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_]+/g, '-')
    // Удаление повторяющихся дефисов
    .replace(/-+/g, '-')
    // Удаление начальных и конечных дефисов
    .replace(/^-+|-+$/g, '');
};

/**
 * Обрезает текст до указанной длины и добавляет многоточие
 * @param {string} text - Исходный текст
 * @param {number} maxLength - Максимальная длина текста
 * @returns {string} - Обрезанный текст
 */
export const truncateText = (text, maxLength) => {
  if (!text || text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

/**
 * Форматирует дату в локализованную строку
 * @param {string|Date} date - Дата для форматирования
 * @returns {string} - Отформатированная дата
 */
export const formatDate = (date) => {
  if (!date) return '';
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};
