import axios from 'axios';
import { getCsrfToken, addSecurityHeaders } from '../utils/csrfUtils';
import { refreshCsrfToken } from '../services/csrfService';

// Создаем экземпляр axios с базовыми настройками
const api = axios.create({
  baseURL: '',  // Используем относительные URL
  timeout: 10000,
  withCredentials: true,
});

// Добавляем интерцептор для запросов
api.interceptors.request.use(
  async (config) => {
    // Логируем запрос
    console.log(`[DEBUG] API Request: ${config.method.toUpperCase()} ${config.url}`);
    
    // Получаем CSRF-токен
    const csrfToken = getCsrfToken();
    
    // Если токен есть, добавляем его в заголовки
    if (csrfToken) {
      config.headers['X-CSRF-Token'] = csrfToken;
    }
    
    // Добавляем токен авторизации, если он есть
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    
    // Добавляем заголовки безопасности
    config.headers = {
      ...config.headers,
      ...addSecurityHeaders(),
    };
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Добавляем интерцептор для обработки ответов
api.interceptors.response.use(
  (response) => {
    // Логируем успешный ответ
    console.log(`[DEBUG] API Response: ${response.status} ${response.config.method.toUpperCase()} ${response.config.url}`);
    return response;
  },
  async (error) => {
    // Логируем ошибку
    console.error(`[ERROR] API Response: ${error.response?.status || 'unknown'} ${error.config?.method?.toUpperCase() || 'unknown'} ${error.config?.url || 'unknown'}`, error.message);
    
    // Проверяем, является ли ошибка связанной с CSRF-токеном
    if (error.response && error.response.status === 403 && 
        error.response.data && error.response.data.error === 'invalid_csrf_token') {
      console.warn('Обнаружена ошибка CSRF-токена, пытаемся обновить токен');
      
      try {
        // Получаем информацию о пользователе
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const isAuthenticated = !!localStorage.getItem('token');
        const isAdmin = user.isAdmin || false;
        
        // Принудительно обновляем CSRF-токен
        await refreshCsrfToken();
        
        // Повторяем исходный запрос с новым токеном
        const originalRequest = error.config;
        const csrfToken = getCsrfToken();
        
        if (csrfToken) {
          originalRequest.headers['X-CSRF-Token'] = csrfToken;
          return axios(originalRequest);
        }
      } catch (refreshError) {
        console.error('Не удалось обновить CSRF-токен:', refreshError);
        
        // Диспатчим событие для обработки ошибки CSRF
        const csrfErrorEvent = new CustomEvent('csrf-error', {
          detail: { message: 'Ошибка CSRF-токена' }
        });
        window.dispatchEvent(csrfErrorEvent);
      }
    }
    
    // Обработка ошибок авторизации
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // Диспатчим событие для обработки ошибки авторизации
      const authErrorEvent = new CustomEvent('auth-error', {
        detail: { message: 'Ошибка авторизации' }
      });
      window.dispatchEvent(authErrorEvent);
    }
    
    return Promise.reject(error);
  }
);

export default api;
