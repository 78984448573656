import React, { lazy, Suspense } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

/**
 * Централизованный сервис для ленивой загрузки компонентов
 */

// Минимальная задержка перед показом индикатора загрузки (мс)
// Уменьшаем с 300 до 100 мс для снижения TBT
const MIN_DELAY = 100;

/**
 * Стандартный компонент-заглушка для отображения во время загрузки
 */
const DefaultFallback = () => (
  <Box 
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      flexDirection: 'column',
      p: 3,
      minHeight: '200px'
    }}
  >
    <CircularProgress size={40} thickness={4} sx={{ color: '#0088cc' }} />
    <Typography variant="body2" sx={{ mt: 2, color: '#64748b' }}>
      Загрузка...
    </Typography>
  </Box>
);

/**
 * Компонент-заглушка для отображения ошибки загрузки
 */
const ErrorFallback = ({ message }) => (
  <Box 
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      flexDirection: 'column',
      p: 3,
      minHeight: '200px',
      bgcolor: 'rgba(239, 68, 68, 0.05)',
      border: '1px solid rgba(239, 68, 68, 0.2)',
      borderRadius: 2
    }}
  >
    <Typography variant="body1" sx={{ color: '#ef4444', fontWeight: 600 }}>
      Ошибка загрузки компонента
    </Typography>
    {message && (
      <Typography variant="body2" sx={{ mt: 1, color: '#64748b' }}>
        {message}
      </Typography>
    )}
  </Box>
);

/**
 * Создает ленивый компонент с отложенной загрузкой
 * @param {Function} importFunc - Функция импорта компонента (например, () => import('./MyComponent'))
 * @param {Object} options - Опции ленивой загрузки
 * @param {React.Component} options.fallback - Компонент-заглушка для отображения во время загрузки
 * @param {boolean} options.delayIndicator - Задержка перед показом индикатора загрузки
 * @param {number} options.minDelay - Минимальная задержка в мс
 * @param {boolean} options.highPriority - Высокий приоритет загрузки (без задержки)
 * @returns {React.Component} - Обернутый ленивый компонент
 */
export const createLazyComponent = (importFunc, options = {}) => {
  const {
    fallback = <DefaultFallback />,
    delayIndicator = true,
    minDelay = MIN_DELAY,
    highPriority = false
  } = options;
  
  // Создаем ленивый компонент
  const LazyComponent = lazy(() => {
    // Если компонент высокого приоритета или не нужна задержка, загружаем сразу
    if (highPriority || !delayIndicator) {
      return importFunc();
    }
    
    // Если нужна задержка перед показом индикатора загрузки
    if (delayIndicator && minDelay > 0) {
      return new Promise(resolve => {
        // Используем requestIdleCallback для выполнения загрузки в свободное время
        const idleCallback = window.requestIdleCallback || ((cb) => setTimeout(cb, 1));
        
        idleCallback(() => {
          Promise.all([
            importFunc(),
            new Promise(r => setTimeout(r, minDelay))
          ])
          .then(([moduleExports]) => resolve(moduleExports))
          .catch(error => resolve({ default: () => <ErrorFallback message={error.message} /> }));
        });
      });
    }
    
    return importFunc();
  });
  
  // Возвращаем обернутый компонент с Suspense
  return (props) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

/**
 * Создает ленивый компонент с обработкой ошибок
 * @param {Function} importFunc - Функция импорта компонента
 * @param {Object} options - Опции ленивой загрузки
 * @returns {React.Component} - Обернутый ленивый компонент с обработкой ошибок
 */
export const createErrorBoundaryLazyComponent = (importFunc, options = {}) => {
  const LazyComponent = createLazyComponent(importFunc, options);
  
  // Компонент с обработкой ошибок
  class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, error: null };
    }
    
    static getDerivedStateFromError(error) {
      return { hasError: true, error };
    }
    
    componentDidCatch(error, errorInfo) {
      console.error('Ошибка в ленивом компоненте:', error, errorInfo);
    }
    
    render() {
      if (this.state.hasError) {
        return <ErrorFallback message={this.state.error?.message} />;
      }
      
      return this.props.children;
    }
  }
  
  // Возвращаем компонент, обернутый в ErrorBoundary
  return (props) => (
    <ErrorBoundary>
      <LazyComponent {...props} />
    </ErrorBoundary>
  );
};

/**
 * Создает набор ленивых компонентов для страницы
 * @param {Object} components - Объект с функциями импорта компонентов
 * @param {Object} options - Общие опции для всех компонентов
 * @param {Object} priorities - Объект с приоритетами компонентов (имя: true/false)
 * @returns {Object} - Объект с ленивыми компонентами
 */
export const createPageComponents = (components, options = {}, priorities = {}) => {
  const result = {};
  
  Object.entries(components).forEach(([name, importFunc]) => {
    // Определяем, является ли компонент высокоприоритетным
    const componentOptions = {
      ...options,
      highPriority: priorities[name] === true
    };
    
    result[name] = createErrorBoundaryLazyComponent(importFunc, componentOptions);
  });
  
  return result;
};

/**
 * Предзагрузка компонента (для упреждающей загрузки)
 * @param {Function} importFunc - Функция импорта компонента
 */
export const preloadComponent = (importFunc) => {
  // Используем requestIdleCallback для загрузки в свободное время
  const idleCallback = window.requestIdleCallback || ((cb) => setTimeout(cb, 1));
  
  idleCallback(() => {
    importFunc().catch(error => {
      console.warn('Ошибка при предзагрузке компонента:', error);
    });
  });
};

/**
 * Предзагрузка нескольких компонентов
 * @param {Array} importFuncs - Массив функций импорта компонентов
 */
export const preloadComponents = (importFuncs) => {
  importFuncs.forEach(preloadComponent);
};

// Экспорт компонентов-заглушек для возможности переопределения
export { DefaultFallback, ErrorFallback };
