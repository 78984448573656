import api from '../services/api';
import { addSecurityHeaders } from './csrfUtils';

/**
 * Функция для безопасного удаления с подтверждением
 * @param {string} url - URL для удаления
 * @param {Object} options - Дополнительные опции
 * @param {boolean} options.confirmed - Флаг подтверждения удаления
 * @returns {Promise<any>} Результат запроса
 * @throws {Error} Ошибка с сообщением CONFIRMATION_REQUIRED, если требуется подтверждение
 */
export const secureDelete = async (url, options = {}) => {
  try {
    // Если операция уже подтверждена, добавляем заголовок подтверждения
    const headers = {};
    
    // Используем X-Confirmation вместо X-Admin-Confirmation
    if (options.confirmed) {
      headers['X-Confirmation'] = 'confirm-delete';
    }
    
    // Выполняем запрос на удаление
    const response = await api.delete(url, { headers });
    return response.data;
  } catch (error) {
    // Если ошибка требует подтверждения, выбрасываем специальную ошибку
    if (error.message === 'CONFIRMATION_REQUIRED') {
      throw new Error('CONFIRMATION_REQUIRED');
    }
    
    // Если другая ошибка, логируем и пробрасываем дальше
    console.error('Ошибка при выполнении безопасного удаления:', error);
    
    // Добавляем информацию о типе ошибки для более понятных сообщений
    if (error.response) {
      if (error.response.status === 403) {
        throw new Error('Доступ запрещен. У вас нет прав для выполнения этой операции.');
      } else if (error.response.status === 401) {
        throw new Error('Требуется авторизация. Пожалуйста, войдите в систему.');
      } else if (error.response.status === 404) {
        throw new Error('Элемент не найден. Возможно, он был уже удален.');
      }
    }
    
    throw error;
  }
};

/**
 * Хук для использования безопасного удаления в компонентах
 * @param {Function} onSuccess - Колбэк при успешном удалении
 * @param {Function} onError - Колбэк при ошибке
 * @returns {Function} Функция для безопасного удаления
 */
export const useSecureDelete = (onSuccess, onError) => {
  return async (url, options = {}) => {
    try {
      const response = await secureDelete(url, options);
      if (onSuccess) {
        onSuccess(response);
      }
      return response;
    } catch (error) {
      if (error.message === 'CONFIRMATION_REQUIRED') {
        // Пробрасываем ошибку для обработки в компоненте
        throw error;
      }
      
      if (onError) {
        onError(error);
      }
      throw error;
    }
  };
};
