/**
 * Сервис для работы с CSRF-токенами
 */
import api from './api';

/**
 * Получить CSRF-токен с сервера
 * @returns {Promise<Object>} Результат запроса
 */
export const getCsrfToken = async () => {
  try {
    const response = await api.get('/api/csrf/token');
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении CSRF-токена:', error);
    return null;
  }
};

/**
 * Обновить CSRF-токен
 * @returns {Promise<Object>} Результат запроса
 */
export const refreshCsrfToken = async () => {
  try {
    const response = await api.get('/api/csrf/token', { 
      timeout: 3000,  // Устанавливаем таймаут в 3 секунды
      validateStatus: (status) => status < 500 // Считаем успешными все ответы, кроме 5xx
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при обновлении CSRF-токена:', error);
    return null;
  }
};

export default {
  getCsrfToken,
  refreshCsrfToken
};
